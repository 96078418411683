import React from 'react';
import {useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {resetPassword as resetPasswordMutation} from '../../graphql/mutations';

import {
  Box,
  Flex,
  WrapperSmall,
  Text,
  Heading,
  FormInput,
  Button,
  NotificationContainer,
  useNotification,
  locale,
  theme
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, locale.min6Chars)
    .required(locale.required),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], locale.passwordsMustMatch)
    .required(locale.required)
});

const ChangePassword = props => {
  const {token} = props.token;
  const [notifications, notify] = useNotification();
  const [resetPassword] = useMutation(resetPasswordMutation, {
    onCompleted: () =>
      notify('A suas password for alterada com sucesso.', 'success'),
    onError: () => notify()
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      resetPassword({
        variables: {
          data: {
            password: values.password,
            passwordConfirmation: values.passwordConfirmation,
            resetPasswordToken: token
          }
        }
      });
    }
  });

  return (
    <Box padding="1rem 0 5rem 0 ">
      <NotificationContainer {...notifications} />
      <WrapperSmall>
        <form onSubmit={formik.handleSubmit}>
          <Box margin="3rem 0 0 0" padding="2.5rem 2rem" elevated>
            <Heading level="1" size="1.5rem" weight="500" padding="0 0 1rem">
              Recuperar palavra-passe
            </Heading>
            <Text font="Lato" size="1.125rem" padding="0 0 2rem">
              Defina a sua palavra-passe para ter acesso ao Portal Innova|GO.
            </Text>

            <FormInput
              required
              label="Palavra-passe"
              name="password"
              type="password"
              placeholder="password"
              formik={formik}
            />
            <FormInput
              required
              label="Repetir palavra-passe"
              name="passwordConfirmation"
              type="password"
              placeholder="password"
              formik={formik}
            />

            <Flex justify="flex-end">
              <Button
                type="submit"
                elevated
                caps
                background={theme.colors.brand}
                width="33.33%"
              >
                submeter
              </Button>
            </Flex>
          </Box>
        </form>
      </WrapperSmall>
    </Box>
  );
};

export default ChangePassword;
