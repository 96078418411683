import React, {useState} from 'react';
import {navigate} from 'hookrouter';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {
  Button,
  Heading,
  Loader,
  Pagination,
  StickyGrid,
  Table,
  TableMenu,
  Wrapper,
  Badge,
  NotificationContainer,
  useNotification,
  locale,
  formatDate
} from '@innovago/ui';

import {getProducts} from '../../graphql/queries';
import {deleteProduct as deleteProductMutation} from '../../graphql/mutations';

const getItemStatusAndType = (item) => {
  if (item.integration_code && item.import_status === 'error') {
    return ['unavailable', 'inactive']
  }

  return [item.status, item.status];
};

const printTitle = (item) => {
  return <div>{item.title}<br/><small>menu title: {item.short_title || item.title}</small></div>;
};

const Products = () => {
  const [notifications, notify] = useNotification();
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({updated_at: 'desc'});
  const queryVars = {
    limit,
    order,
    offset
  };
  const {data, loading} = useQuery(getProducts, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: () => notify()
  });
  const [deleteProduct] = useMutation(deleteProductMutation, {
    onCompleted: () => notify(locale.deletedSuccessfully, 'success'),
    onError: () => notify()
  });

  function handleRemoveItem(item) {
    deleteProduct({
      /* update(cache) {
        try {
          const cacheOptions = {
            query: getProducts,
            variables: queryVars
          };

          const cachedData = cache.readQuery(cacheOptions);

          if (!cachedData || !cachedData.product_aggregate) {
            return;
          }

          cache.writeQuery({
            ...cacheOptions,
            data: {
              product_aggregate: {
                ...cachedData.product_aggregate,
                aggregate: {
                  ...cachedData.product_aggregate.aggregate,
                  count: cachedData.product_aggregate.aggregate.count - 1
                }
              },
              product: cachedData.product.length
                ? cachedData.product.filter(product => product.id !== item.id)
                : []
            }
          });
        } catch (err) {
          console.log('err updating cache: ', err);
        }
      }, */
      refetchQueries: ['getProducts'],
      variables: {
        id: item.id
      }
    });
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          {locale.product.plural}
        </Heading>
        <Button href="/product/new" filled>
          {locale.add}
        </Button>
      </StickyGrid>
      <Table
        loading={loading}
        columns={[
          {
            label: locale.title,
            property: 'title',
            sortable: true,
            format: item => printTitle(item)
          },
          {
            label: locale.productType,
            property: 'type',
            sortable: true,
            format: item => locale[item.type]
          },
          {
            label: locale.updatedAt,
            property: 'updated_at',
            sortable: true,
            format: item => formatDate(item.updated_at)
          },
          {
            label: locale.status,
            property: 'status',
            format: item => {
              const [status, type] = getItemStatusAndType(item);

              return <Badge label={locale[status]} type={type} />
            }
          },
          {
            label: locale.actions,
            width: '100px',
            format: item => (
              <TableMenu
                actions={[
                  {
                    label: 'editar',
                    onClick: () => navigate(`/product/${item.id}/edit`)
                  },
                  {
                    label: 'eliminar',
                    onClick: () => handleRemoveItem(item)
                  }
                ]}
              />
            )
          }
        ]}
        data={data && data.product}
        sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
        onClickSort={sort =>
          setOrder({
            [sort.column]: sort.order
          })
        }
      />
      {data && data.product_aggregate.aggregate && (
        <Pagination
          current={offset / limit + 1}
          total={data.product_aggregate.aggregate.count}
          onChange={page => setOffset(limit * (page - 1))}
        />
      )}
    </Wrapper>
  );
};

export default Products;
