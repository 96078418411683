import React, {useContext} from 'react';
import Auth from '@aws-amplify/auth';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {AuthContext} from '../../contexts/AuthContext';

import {
  Button,
  FormInput,
  FormPassword as FieldPassword,
  Grid2,
  Heading,
  Loader,
  Wrapper,
  NotificationContainer,
  useNotification,
  locale,
  theme,
} from '@innovago/ui';

import {updateUser as updateUserMutation} from '../../graphql/mutations';
import {getProfile} from '../../graphql/queries';

const validationPersonal = Yup.object().shape({
  forename: Yup.string().required(locale.required),
  surname: Yup.string().required(locale.required),
});

const FormPersonal = ({initialValues, onSubmit = () => {}}) => {
  const formik = useFormik({
    initialValues,
    validationSchema: validationPersonal,
    onSubmit: values => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading
        padding="2rem 0"
        level="2"
        color={theme.colors.darkBlue}
        size="1.5rem"
      >
        {locale.personalData}
      </Heading>
      <FormInput
        type="text"
        name="forename"
        label={locale.forename}
        formik={formik}
      />
      <FormInput
        type="text"
        name="surname"
        label={locale.surname}
        formik={formik}
      />
      <FormInput
        type="email"
        name="email"
        label={locale.email}
        formik={formik}
        disabled={true}
      />
      <Button type="submit" filled>
        {locale.submit}
      </Button>
    </form>
  );
};

const validationPassword = Yup.object().shape({
  oldPassword: Yup.string().required(locale.required),
  newPassword: Yup.string()
    .min(6, locale.passwordMin)
    .required(locale.required),
});

const FormPassword = ({onSubmit = () => {}}) => {
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: validationPassword,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: values => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading
        padding="2rem 0"
        level="2"
        color={theme.colors.darkBlue}
        size="1.5rem"
      >
        {locale.passwordChange}
      </Heading>
      <FieldPassword
        name="oldPassword"
        label={locale.oldPassword}
        formik={formik}
      />
      <FieldPassword
        name="newPassword"
        label={locale.newPassword}
        formik={formik}
      />
      <Button type="submit" filled>
        {locale.submit}
      </Button>
    </form>
  );
};

const Profile = () => {
  const {logout, user} = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const {data, loading} = useQuery(getProfile, {
    skip: !user,
    variables: {
      sub: user.attributes.sub,
    },
    onError: () => notify(),
  });
  const [updateUser] = useMutation(updateUserMutation, {
    onCompleted: () => notify(locale.profileUpdated, 'success'),
    onError: () => notify(),
  });

  function handleUpdate({id, forename, surname}) {
    updateUser({
      variables: {data: {forename, surname}, id},
    });
  }

  async function handleChangePassword({oldPassword, newPassword}) {
    try {
      await Auth.changePassword(user, oldPassword, newPassword);

      return notify(locale.passwordUpdated, 'success');
    } catch (err) {
      throw notify(err.message);
    }
  }

  if (loading) {
    return <Loader />;
  }

  if (!data || (data && !data.user)) {
    logout();
    return null;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <Heading padding="2rem 0 1rem" level="1">
        {locale.profile}
      </Heading>
      <Grid2 padding="0 0 3rem">
        <FormPersonal initialValues={data.user[0]} onSubmit={handleUpdate} />
        <FormPassword onSubmit={handleChangePassword} />
      </Grid2>
    </Wrapper>
  );
};

export default Profile;
