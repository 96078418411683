import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {
  Box,
  Grid2,
  Flex,
  Heading,
  Text,
  Button,
  FormInput,
  FormTextarea,
  FormFile,
  Image,
  Loader,
  Wrapper,
  locale,
  theme,
  NotificationContainer,
  useNotification,
  FormCheckbox,
} from '@innovago/ui';

import {setting} from '../../graphql/queries';
import {
  insertSetting as insertSettingMutation,
  refreshProducts as refreshProductsMutation,
} from '../../graphql/mutations';

const {REACT_APP_UPLOAD_URL} = process.env;

const validationSchema = Yup.object().shape({
  brokersEmail: Yup.string().required('Obrigatório'),
  claimsEmail: Yup.string().required('Obrigatório'),
  facebookLink: Yup.string(),
  linkedinLink: Yup.string(),
  youtubeLink: Yup.string(),
});

const initialState = [
  {
    title: 'Emails',
    items: {
      brokersEmail: {
        type: 'text',
        value: null,
        label:
          'Emails que recebem notificações sobre registos de brokers separados por ; (ex: info@innovarisk.pt;innovago@innovarisk.pt)',
        secured: true,
      },
      claimsEmail: {
        type: 'text',
        value: null,
        label:
          'Emails que recebem notificações sobre os sinistros separados por ; (ex: info@innovarisk.pt;sinistros@innovarisk.pt)',
        secured: true,
      },
    },
  },
  {
    title: 'Social Networks',
    items: {
      facebookLink: {
        type: 'text',
        value: null,
        label: 'Link do Facebook',
        secured: false,
      },
      linkedinLink: {
        type: 'text',
        value: null,
        label: 'Link do Linkedin',
        secured: false,
      },
      youtubeLink: {
        type: 'text',
        value: null,
        label: 'Link do Youtube',
        secured: false,
      },
    },
  },
  {
    title: 'Widget',
    items: {
      widgetText: {
        type: 'text',
        value: null,
        label: 'Texto do Widget',
        secured: false,
      },
      widgetColors: {
        type: 'text',
        value: null,
        label:
          'Cores do widget em códigos hexadecimais separados por ; (ex: #e32618;#676767;#b4b4b4)',
        secured: false,
      },
      widgetContent: {
        type: 'text',
        value: null,
        label:
          'Telefone e Email do widget separados por ; (ex: 00351919999999;info@innovarisk.eu)',
        secured: false,
      },
    },
  },
  {
    title: 'Login',
    items: {
      loginImage: {
        type: 'file',
        url: null,
        label: 'Imagem de écrã de login broker',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      brokerLoginText: {
        type: 'area',
        value: null,
        label: 'Frase do écran de login de broker',
        secured: false,
      },
      clientLoginImage: {
        type: 'file',
        url: null,
        label: 'Imagem de écrã de login client',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      clientLoginText: {
        type: 'area',
        value: null,
        label: 'Frase do écran de login de client',
        secured: false,
      },
    },
  },
  {
    title: 'Dashboard',
    items: {
      campaignImage: {
        type: 'file',
        url: null,
        label: 'Imagem das campanhas no Dashboard',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      infoImage: {
        type: 'file',
        url: null,
        label: 'Imagem das novidades no Dashboard',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      newsletterImage: {
        type: 'file',
        url: null,
        label: 'Imagem das newsletters no Dashboard',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      trainingsImage: {
        type: 'file',
        url: null,
        label: 'Imagem das formações no Dashboard',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      nominationPdf: {
        type: 'file',
        url: null,
        label: 'Ficha de nomeação',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
    },
  },
  {
    title: 'Mode de manutenção',
    items: {
      maintenanceMode: {
        type: 'checkbox',
        value: false,
        label: 'Activar modo de manutenção?',
        secured: false,
      },
      maintenanceImage: {
        type: 'file',
        url: null,
        label: 'Imagem de modo de manutenção',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
      maintenanceMobileImage: {
        type: 'file',
        url: null,
        label: 'Imagem de modo de manutenção / Mobile',
        filesize: null,
        filetype: null,
        filename: null,
        secured: false,
      },
    },
  },
];

const files = {};

const Settings = () => {
  const [filesToUpload, setFilesToUpload] = useState(files);
  const [selectedTab, setSelectedTab] = useState(0);
  const [settingsData, setSettingsData] = useState(initialState);
  const [notifications, notify] = useNotification();
  const [insertSetting] = useMutation(insertSettingMutation, {
    onCompleted: () => {
      notify('Settings saved', 'success');
    },
    onError: () => notify(),
  });

  const [refreshProducts] = useMutation(refreshProductsMutation, {
    onCompleted: data => {
      if (data.refreshProducts.success) {
        notify('Os produtos foram actualizados com sucesso', 'success');
      } else {
        notify('Ocorreu um erro, por favor tente mais tarde', 'error');
      }
    },
    onError: error => {
      console.error(error);
      notify('Ocorreu um erro, por favor tente mais tarde', 'error');
    },
  });

  //getData
  const {data, loading} = useQuery(setting, {
    fetchPolicy: 'cache-and-network',
    onError: () => {
      notify();
    },
  });

  //update state after data is loaded
  useEffect(() => {
    if (data) {
      data.setting.map(item => {
        try {
          const parsedValue = JSON.parse(item.value);
          updateSettingData(item.name, parsedValue.url, 'url');
          updateSettingData(item.name, parsedValue.filesize, 'filesize');
          updateSettingData(item.name, parsedValue.filetype, 'filetype');
          updateSettingData(item.name, parsedValue.filename, 'filename');
          if (item.name === 'maintenanceMode') {
            formik.setFieldValue(
              item.name,
              item.value === 'true' ? true : false
            );
          } else {
            formik.setFieldValue(item.name, item.value);
          }
        } catch (e) {
          updateSettingData(item.name, item.value, 'value');

          formik.setFieldValue(item.name, item.value);
        }
        return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // update settingsData
  const updateSettingData = async (name, value, prop) => {
    const newSettingsData = [...settingsData];
    newSettingsData.map(cat =>
      Object.entries(cat.items).map(([key, item]) => {
        if (key === name) {
          item[prop] = value;
        }
        return [key, item];
      })
    );
    await setSettingsData(newSettingsData);
  };

  //upload a file to browser
  const onFileChange = e => {
    const name = e.currentTarget.id;
    const uploadedFile = e.target.files[0];
    const reader = new FileReader();

    if (uploadedFile) {
      reader.readAsDataURL(uploadedFile);
      reader.addEventListener(
        'load',
        () => {
          const file = reader.result;
          updateSettingData(name, uploadedFile.size, 'filesize');
          updateSettingData(name, uploadedFile.type, 'filetype');
          updateSettingData(name, uploadedFile.name, 'filename');
          updateSettingData(name, file, 'file');

          setFilesToUpload(prevState => ({
            ...prevState,
            [name]: {
              file: file,
              filename: uploadedFile.name,
              size: uploadedFile.size,
              type: uploadedFile.type,
            },
          }));
        },
        false
      );
    }
  };

  //submit form
  const formik = useFormik({
    initialValues: {
      brokersEmail: '',
      claimsEmail: '',
      facebookLink: '',
      linkedinLink: '',
      youtubeLink: '',
      widgetText: '',
      widgetContent: '',
      widgetColors: '',
      maintenanceMode: false,
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const d = await uploadFiles();
      insertSettings(d);
    },
  });

  //upload files to s3
  const uploadFiles = async () => {
    const d = await Promise.all(
      Object.keys(filesToUpload).map(async key => {
        const media = {
          fieldName: key,
          fileName: filesToUpload[key].filename,
          type: filesToUpload[key].type,
          file: filesToUpload[key].file,
        };

        return await fetch(REACT_APP_UPLOAD_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(media),
        })
          .then(response => response.json())
          .then(response => {
            updateSettingData(key, response.secureUrl, 'url');
            return {[key]: response.secureUrl};
          });
      })
    );

    return d;
  };

  //do mutation
  const insertSettings = d => {
    const mutationData = [];
    settingsData.map(cat =>
      Object.entries(cat.items).forEach(([key, item]) => {
        if (item.type !== 'file') {
          mutationData.push({
            name: key,
            value: String(item.value),
            secured: item.secured ? item.secured : false,
          });
        } else {
          const url = d.find(item => item[key] === key);
          mutationData.push({
            name: key,
            value: JSON.stringify({
              url: url ? url[item] : item.url ? item.url : null,
              filesize: item.filesize,
              filetype: item.filetype,
              filename: item.filename,
            }),
            secured: item.secured,
          });
        }
      })
    );

    insertSetting({
      variables: {
        objects: mutationData,
      },
    });
  };

  const onFileRemove = name => {
    const newFiles = filesToUpload;
    delete newFiles['name'];
    setFilesToUpload(newFiles);
  };

  const onChange = (field, e) => {
    const value = e.target.value;

    formik.setFieldValue(field, e.target.value);
    updateSettingData(field, value, 'value');
  };

  if (loading || !settingsData) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <Heading padding="2rem 0" level="1">
        {locale.settings}
      </Heading>
      <Box margin="0 0 3rem">
        <Button
          caps
          background={theme.colors.brand}
          onClick={() => {
            refreshProducts();
          }}
          width="auto"
        >
          Actualizar os produtos
        </Button>
      </Box>
      <Box
        margin="0 0 3rem"
        height="2px"
        width="100%"
        backgroundColor={theme.colors.cloudyBlue}
      ></Box>
      <Box>
        <Box width="100%" display="flex">
          {settingsData.map((item, index) => (
            <button
              href="#"
              key={`tab${index}`}
              style={{
                fontFamily: 'Lato',
                padding: '2rem',
                border: `1px solid ${theme.colors.cloudyBlue2}`,
                textDecoration: 'none',
                color: `${
                  selectedTab === index
                    ? theme.colors.white
                    : theme.colors.black
                }`,
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
                backgroundColor: `${
                  selectedTab === index
                    ? theme.colors.darkBlue
                    : theme.colors.white
                }`,
              }}
              onClick={e => {
                e.preventDefault();
                setSelectedTab(index);
              }}
            >
              {item.title}
            </button>
          ))}
        </Box>
      </Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          {settingsData.map((cat, index) => {
            return (
              <Box
                key={`tabC${index}`}
                padding="2rem"
                borderRight={`1px solid ${theme.colors.cloudyBlue2}`}
                borderLeft={`1px solid ${theme.colors.cloudyBlue2}`}
                borderBottom={`1px solid ${theme.colors.cloudyBlue2}`}
                borderTop={`1px solid ${theme.colors.cloudyBlue2}`}
                display={selectedTab === index ? 'block' : 'none'}
              >
                <Grid2 align="flex-start">
                  {Object.entries(cat.items).map(([key, value]) => {
                    switch (value.type) {
                      case 'text':
                        return (
                          <Box key={key} margin="0 0 2rem">
                            <FormInput
                              type={value.type}
                              name={key}
                              label={value.label}
                              border={true}
                              formik={formik}
                              value={formik.values[key]}
                              onChange={e => onChange(key, e)}
                            />
                          </Box>
                        );
                      case 'checkbox':
                        return (
                          <Box key={key} margin="0 0 2rem">
                            <FormCheckbox
                              formik={formik}
                              label={value.label}
                              name={key}
                              value={formik.values[key] === true ? true : false}
                              onClick={e => {
                                e.preventDefault();

                                if (formik.values[key] === true) {
                                  updateSettingData(key, 'false', 'value');
                                  formik.setFieldValue(key, false);
                                } else {
                                  updateSettingData(key, 'true', 'value');
                                  formik.setFieldValue(key, true);
                                }
                              }}
                            />
                          </Box>
                        );
                      case 'area':
                        return (
                          <Box key={key} margin="0 0 2rem">
                            <FormTextarea
                              type={value.type}
                              name={key}
                              label={value.label}
                              border={true}
                              formik={formik}
                              value={formik.values[key]}
                              onChange={e => onChange(key, e)}
                            />
                          </Box>
                        );
                      case 'file':
                        return (
                          <Box key={key} margin="0 0 2rem">
                            <FormFile
                              type={value.type}
                              name={key}
                              formik={formik}
                              data={value}
                              size={value.filesize}
                              onChange={onFileChange}
                              onRemove={() => onFileRemove(key)}
                              required
                            />

                            {value.file &&
                              !value.url &&
                              (value.filetype === 'image/png' ||
                                value.filetype === 'image/jpeg') && (
                                <Image
                                  src={value.file}
                                  style={{maxHeight: '200px'}}
                                />
                              )}

                            {value.url &&
                              (value.filetype === 'image/png' ||
                                value.filetype === 'image/jpeg') && (
                                <Image
                                  src={value.url}
                                  style={{maxHeight: '200px'}}
                                />
                              )}

                            {value.url &&
                              value.filetype === 'application/pdf' && (
                                <Button link href={value.url} target="_blank">
                                  Ver ficheiro
                                </Button>
                              )}

                            {!value.url && !value.file && (
                              <Text size=".75rem" align="center">
                                Ainda não foi carregado um ficheiro
                              </Text>
                            )}
                          </Box>
                        );
                      default:
                        return null;
                    }
                  })}
                </Grid2>
              </Box>
            );
          })}

          <Flex justify="flex-end">
            <Button
              caps
              background={theme.colors.brand}
              width="33.33%"
              type="submit"
              margin="2rem 0 0"
            >
              gravar
            </Button>
          </Flex>
        </form>
      </Box>
    </Wrapper>
  );
};

export default Settings;
