import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {
  Box,
  Flex,
  Table,
  Button,
  Heading,
  Text,
  Loader,
  StickyGrid,
  Wrapper,
  locale,
  formatDate,
  theme,
  NotificationContainer,
  useNotification,
  ModelWithOverlay,
  Grid3,
  Badge,
  Pdf,
} from '@innovago/ui';

import {CreateInvite} from './createInvite';
import {ConfirmationOverlay} from './confirmationOverlay';
import {RejectOverlay} from './rejectOverlay';
import {RejectPermanentOverlay} from './rejectPermanentOverlay';

import {getBroker, getUserPasswordToken} from '../../graphql/queries';
import {
  approveBroker as approveBrokerMutation,
  rejectBroker as rejectBrokerMutation,
  rejectBrokerPermanently as rejectBrokerPermanentlyMutation,
  activateBroker as activateBrokerMutation,
  inactivateBroker as inactivateBrokerMutation,
  deleteInvite as deleteInviteMutation,
  createInvite as createInviteMutation,
  insertInvite as insertInviteMutation,
  setBrokerEmail as setBrokerEmailMutation,
  promoteUser as promoteUserMutation,
  demoteUser as demoteUserMutation,
  requestPasswordReset as requestPasswordResetMutation,
  updateSubmission as updateSubmissionMutation,
  updateInviteStatus as updateInviteStatusMutation,
  activateUser as activateUserMutation,
  deactivateUser as deactivateUserMutation,
} from '../../graphql/mutations';

const BrokerDetail = props => {
  const id = props.id.id;

  const [notifications, notify] = useNotification();
  const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [confirmationOverlay, setConfirmationOverlay] = useState(false);
  const [resetEmail, setResetEmail] = useState(null);
  const [resetPasswordRequest, setResetPasswordRequest] = useState(null);
  const [rejectOverlay, setRejectOverlay] = useState(false);
  const [rejectPermanentOverlay, setRejectPermanentOverlay] = useState(false);
  const [createInviteOverlay, setCreateInviteOverlay] = useState(false);

  const [requestPasswordReset] = useMutation(requestPasswordResetMutation, {
    onCompleted: data => {
      if (data.requestPasswordReset) {
        setResetPasswordRequest(true);
      }
    },
    onError: () => {
      notify('adsadas');
    },
  });

  const [promoteUserStatus] = useMutation(promoteUserMutation, {
    onCompleted: () => {
      notify('O user foi promovido a team admin', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [demoteUserStatus] = useMutation(demoteUserMutation, {
    onCompleted: () => {
      notify('O team admin foi despromovido a user', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [createInviteStatus] = useMutation(createInviteMutation, {
    onCompleted: () => {
      notify(
        'O broker irá receber por email o convite para se inscrever',
        'success'
      );
      setCreateInviteOverlay(false);
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [insertInviteStatus] = useMutation(insertInviteMutation, {
    onCompleted: () => {
      notify(
        'O broker irá receber por email o convite para se inscrever',
        'success'
      );
      setCreateInviteOverlay(false);
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [approveBrokerStatus] = useMutation(approveBrokerMutation, {
    onCompleted: () => {
      setIsSendingRequest(false);
      notify('O broker foi aprovado.', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [setBrokerEmailStatus] = useMutation(setBrokerEmailMutation, {
    onCompleted: () => {
      notify('O broker foi actualizado.', 'success');
    },
    onError: () => {
      notify();
    },
  });

  const [rejectBrokerStatus] = useMutation(rejectBrokerMutation, {
    onCompleted: () => {
      notify('O broker irá receber por email os motivos da recusa', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [rejectBrokerPermanentlyStatus] = useMutation(
    rejectBrokerPermanentlyMutation,
    {
      onCompleted: () => {
        notify(
          'O broker irá receber por email os motivos da recusa',
          'success'
        );
      },
      onError: () => {
        notify();
      },
      refetchQueries: ['getBroker'],
    }
  );

  const [activateBrokerStatus] = useMutation(activateBrokerMutation, {
    onCompleted: () => {
      notify('O broker foi activado.', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [inactivateBrokerStatus] = useMutation(inactivateBrokerMutation, {
    onCompleted: () => {
      notify('O broker foi desactivado.', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [deleteInvite] = useMutation(deleteInviteMutation, {
    onCompleted: () => {
      notify('O convite foi eliminado.', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [updateSubmissionStatus] = useMutation(updateSubmissionMutation, {
    onCompleted: () => {
      notify('O broker foi actualizado', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [updateInviteStatus] = useMutation(updateInviteStatusMutation, {
    onCompleted: () => {
      notify('O invite foi actualizado', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [activateUserStatus] = useMutation(activateUserMutation, {
    onCompleted: () => {
      notify('O user foi activado', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  const [deactivateUserStatus] = useMutation(deactivateUserMutation, {
    onCompleted: () => {
      notify('O user foi desactivado', 'success');
    },
    onError: () => {
      notify();
    },
    refetchQueries: ['getBroker'],
  });

  useQuery(getUserPasswordToken, {
    skip: !resetPasswordRequest,
    variables: {
      email: resetEmail,
    },
    onCompleted: data => {
      setResetPasswordRequest(false);
      window.open(
        process.env.REACT_APP_FRONTEND_URL +
          '/reset-password/' +
          data.user[0].reset_password_token,
        '_blank'
      );
    },
    onError: () => {
      notify('asdasda');
    },
  });

  const handleResetPassword = email => {
    setResetEmail(email.toLowerCase());
    setResetPasswordRequest(false);
    requestPasswordReset({
      variables: {
        data: {
          email: email.toLowerCase(),
          sendEmail: false,
        },
      },
    });
  };

  const handleSubmission = (id, allow) => {
    updateSubmissionStatus({
      variables: {
        allowSubmission: allow,
        id: id,
      },
    });
  };

  const {data, loading} = useQuery(getBroker, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
    onError: () => {
      notify();
    },
  });

  let teamAdmins = 0;

  if (data) {
    let usersTeamAdmins = data.broker[0].users.filter(
      user => user.role === 'team_admin' && user.email !== 'null'
    );
    let inviteTeamAdmin = data.invite.filter(invite => {
      return invite.role === 'team_admin' && invite.email;
    });
    teamAdmins = usersTeamAdmins.length + inviteTeamAdmin.length;
  }

  const promoteUserHandler = item => {
    promoteUserStatus({
      variables: {
        id: item.id,
      },
    });
  };

  const demoteUserHandler = item => {
    demoteUserStatus({
      variables: {
        id: item.id,
      },
    });
  };

  const inactivateBrokerHandler = () => {
    setConfirmationOverlay(false);
    inactivateBrokerStatus({
      variables: {
        id,
      },
    });
  };

  const approveBrokerHandler = () => {
    if (teamAdmins > 0) {
      setIsSendingRequest(true);
      approveBrokerStatus({
        variables: {
          data: {
            id,
          },
        },
      });
    } else {
      notify(
        'Por favor crie um convite para um team admin antes de aprovar o broker',
        'error'
      );
    }
  };

  const activateBrokerHandler = () => {
    activateBrokerStatus({
      variables: {
        id,
      },
    });
  };

  const deleteInviteHandler = id => {
    deleteInvite({variables: {id: id}});
  };

  const addInviteHandler = () => {
    setCreateInviteOverlay(true);
  };

  const closeModal = () => {
    setCreateInviteOverlay(false);
  };

  const toggleInviteStatusHandler = (status, inviteID) => {
    updateInviteStatus({
      variables: {
        status: status === 'inactive' ? 'active' : 'inactive',
        id: inviteID,
      },
    });
  };

  const toggleUserStatusHandler = (status, userID) => {
    status === 'inactive'
      ? activateUserStatus({
          variables: {
            id: userID,
          },
        })
      : deactivateUserStatus({
          variables: {
            id: userID,
          },
        });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />

      {confirmationOverlay && (
        <ConfirmationOverlay
          inactivateBrokerHandler={inactivateBrokerHandler}
          setConfirmationOverlay={setConfirmationOverlay}
        />
      )}

      {rejectOverlay && (
        <RejectOverlay
          setRejectOverlay={setRejectOverlay}
          rejectBrokerStatus={rejectBrokerStatus}
          id={id}
        />
      )}

      {rejectPermanentOverlay && (
        <RejectPermanentOverlay
          setRejectPermanentOverlay={setRejectPermanentOverlay}
          rejectBrokerPermanentlyStatus={rejectBrokerPermanentlyStatus}
          id={id}
        />
      )}

      {createInviteOverlay && (
        <ModelWithOverlay top="50%" onClose={closeModal}>
          <CreateInvite
            broker={data.broker[0]}
            createInviteStatus={createInviteStatus}
            insertInviteStatus={insertInviteStatus}
            teamAdmins={teamAdmins}
            setBrokerEmailStatus={setBrokerEmailStatus}
          ></CreateInvite>
        </ModelWithOverlay>
      )}
      <StickyGrid>
        <Box padding="3rem 0 3rem">
          <Text>PERFIL BROKER</Text>
          <Flex align="center" justify="flex-start">
            <Badge
              type={data.broker[0].status}
              label={locale.statusObj[data.broker[0].status]}
            ></Badge>
            <Heading
              font="montserrat"
              weight="500"
              level="1"
              padding="0 0 0 1rem"
            >
              {data.broker[0].name}
            </Heading>
          </Flex>
          {!isSendingRequest && (
            <Flex align="center" justify="flex-start">
              {(data.broker[0].status === 'pendent' ||
                data.broker[0].status === 'submitted') && (
                <Box padding="1rem 0">
                  <Button
                    width="auto"
                    background={theme.colors.success}
                    onClick={() => approveBrokerHandler(id)}
                  >
                    {locale.approve}
                  </Button>
                  <Button
                    width="auto"
                    margin="0 0 0 1rem"
                    background={theme.colors.brand}
                    onClick={() => setRejectOverlay(true)}
                  >
                    {locale.reject}
                  </Button>
                </Box>
              )}
              {data.broker[0].status === 'active' && (
                <Button
                  width="auto"
                  background={theme.colors.brand}
                  onClick={() => setConfirmationOverlay(true)}
                >
                  {locale.activate}
                </Button>
              )}
              {data.broker[0].status === 'inactive' && (
                <Button
                  width="auto"
                  background={theme.colors.brand}
                  onClick={() => activateBrokerHandler()}
                >
                  {locale.inactivate}
                </Button>
              )}
              {data.broker[0].status !== 'rejected' && (
                <Button
                  margin="0 0 0 1rem"
                  width="auto"
                  background={theme.colors.brand}
                  onClick={() => setRejectPermanentOverlay(true)}
                >
                  Rejeitar definitivamente
                </Button>
              )}
              {data.broker[0].allow_submission && (
                <Button
                  background={theme.colors.darkBlue}
                  margin="0 0 0 1rem"
                  width="auto"
                  display="inline-block"
                  onClick={() =>
                    handleSubmission(
                      data.broker[0].id,
                      !data.broker[0].allow_submission
                    )
                  }
                >
                  Desactivar colocação
                </Button>
              )}
              {!data.broker[0].allow_submission && (
                <Button
                  background={theme.colors.dirtyBlue}
                  margin="0 0 0 1rem"
                  width="auto"
                  onClick={() =>
                    handleSubmission(
                      data.broker[0].id,
                      !data.broker[0].allow_submission
                    )
                  }
                >
                  Activar colocação
                </Button>
              )}
            </Flex>
          )}
        </Box>
      </StickyGrid>

      <Box padding="0 0 3rem">
        <Heading
          level="3"
          color={theme.colors.darkBlue}
          size="1.5rem"
          padding="0 0 1.5rem"
          weight="500"
        >
          {locale.companyIdentification}
        </Heading>
        <Grid3>
          <Box>
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              size=".875rem"
              padding="0 0 .25rem"
              weight="500"
              letter="2.25px"
              line="1.14"
              font={theme.fonts.lato}
              caps
            >
              {locale.companyName}
            </Heading>
            <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
              {data.broker[0].name}
            </Text>
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              size=".875rem"
              padding="0 0 .25rem"
              weight="500"
              letter="2.25px"
              line="1.14"
              font={theme.fonts.lato}
              caps
            >
              {locale.vat}
            </Heading>
            <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
              {data.broker[0].vat}
            </Text>
          </Box>
          <Box>
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              size=".875rem"
              padding="0 0 .25rem"
              weight="500"
              letter="2.25px"
              line="1.14"
              font={theme.fonts.lato}
              caps
            >
              {locale.phone}
            </Heading>
            <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
              {data.broker[0].phone}
            </Text>
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              size=".875rem"
              padding="0 0 .25rem"
              weight="500"
              letter="2.25px"
              line="1.14"
              font={theme.fonts.lato}
              caps
            >
              {locale.address}
            </Heading>
            <Text padding="0 0 1.5rem" line="1.75" font={theme.fonts.lato}>
              {data.broker[0].correspondance_address_line_1}
              <br />
              {data.broker[0].correspondance_address_line_2}
              <br />
              {data.broker[0].correspondance_address_line_3}
              <br />
              {data.broker[0].correspondance_address_line_4}
            </Text>
          </Box>
          <Box backgroundColor={theme.colors.lightGrey} padding="1rem" elevated>
            <Heading
              level="4"
              color={theme.colors.darkBlue}
              size=".875rem"
              padding="0 0 .25rem"
              weight="500"
              letter="2.25px"
              line="1.14"
              font={theme.fonts.lato}
              caps
            >
              {locale.documents}
            </Heading>
            {data.broker[0].f1[0] && (
              <Box padding="0 0 1rem 0">
                <Button
                  href={data.broker[0].f1[0].url}
                  display="inline-block"
                  width="auto"
                  target="_blank"
                  padding="0"
                >
                  <Flex align="center">
                    <Pdf color={theme.colors.brand} />
                    <Text nowrap size=".875rem" padding="0 0 0 1rem">
                      Ficha de nomeação
                    </Text>
                  </Flex>
                </Button>
              </Box>
            )}
            {data.broker[0].f2[0] && (
              <Box padding="0 0 1rem 0">
                <Button
                  href={data.broker[0].f2[0].url}
                  display="inline-block"
                  width="auto"
                  target="_blank"
                  padding="0"
                >
                  <Flex align="center">
                    <Pdf color={theme.colors.brand} />
                    <Text nowrap size=".875rem" padding="0 0 0 1rem">
                      Apólice de responsabilidade Civil
                    </Text>
                  </Flex>
                </Button>
              </Box>
            )}
            {data.broker[0].f3[0] && (
              <Box padding="0 0 1rem 0">
                <Button
                  href={data.broker[0].f3[0].url}
                  display="inline-block"
                  width="auto"
                  target="_blank"
                  padding="0"
                >
                  <Flex align="center">
                    <Pdf color={theme.colors.brand} />
                    <Text nowrap size=".875rem" padding="0 0 0 1rem">
                      Relatório de Contas
                    </Text>
                  </Flex>
                </Button>
              </Box>
            )}
            {data.broker[0].f4[0] && (
              <Box padding="0 0 1rem 0">
                <Button
                  href={data.broker[0].f4[0].url}
                  display="inline-block"
                  width="auto"
                  target="_blank"
                  padding="0"
                >
                  <Flex align="center">
                    <Pdf color={theme.colors.brand} />
                    <Text nowrap size=".875rem" padding="0 0 0 1rem">
                      Certificado de ASF de mediador
                    </Text>
                  </Flex>
                </Button>
              </Box>
            )}
            {data.broker[0].f5[0] && (
              <Box padding="0 0 1rem 0">
                <Button
                  href={data.broker[0].f5[0].url}
                  display="inline-block"
                  width="auto"
                  target="_blank"
                  padding="0"
                >
                  <Flex align="center">
                    <Pdf color={theme.colors.brand} />
                    <Text nowrap size=".875rem" padding="0 0 0 1rem">
                      IBAN
                    </Text>
                  </Flex>
                </Button>
              </Box>
            )}
          </Box>
        </Grid3>
      </Box>

      <Box padding="0 0 3rem">
        <Heading
          font="montserrat"
          weight="500"
          size="1.5rem"
          color="darkBlue"
          level="3"
          padding="0 0 1.5rem"
        >
          {locale.users}
        </Heading>

        <Table
          loading={loading}
          columns={[
            {
              label: locale.name,
              property: 'forename',
              sortable: false,
              format: user => user.forename,
            },
            {
              label: locale.email,
              property: 'email',
              sortable: false,
              format: user => user.email,
            },
            {
              label: locale.role,
              property: 'role',
              sortable: false,
              format: user => locale[user.role],
            },
            {
              label: locale.status,
              property: 'status',
              sortable: false,
              format: user => (
                <Button
                  background={
                    user.status === 'active'
                      ? theme.colors.dirtyBlue
                      : theme.colors.brand
                  }
                  onClick={() => toggleUserStatusHandler(user.status, user.id)}
                >
                  {locale.statusObj[user.status]}
                </Button>
              ),
            },
            {
              label: locale.actions,
              format: item => {
                if (item.role === 'user') {
                  return (
                    <Button filled onClick={() => promoteUserHandler(item)}>
                      Promover
                    </Button>
                  );
                }
                if (item.role !== 'user') {
                  return (
                    <Button filled onClick={() => demoteUserHandler(item)}>
                      Despromover
                    </Button>
                  );
                }
              },
            },
            {
              label: 'Password',
              property: 'email2',
              sortable: false,
              format: item => (
                <Button
                  filled
                  onClick={e => {
                    e.preventDefault();
                    handleResetPassword(item.email.toLowerCase());
                    return false;
                  }}
                >
                  Reset Password
                </Button>
              ),
            },
          ]}
          data={data.broker[0].users}
        />
      </Box>

      <Box padding="0 0 3rem">
        <Flex align="center" justify="space-between" padding="0 0 1.5rem">
          <Heading
            font="montserrat"
            weight="500"
            size="1.5rem"
            color="darkBlue"
            level="3"
          >
            {locale.invites}
          </Heading>
          {data.broker[0].status !== 'rejected' && (
            <Button filled onClick={() => addInviteHandler()}>
              Criar convite
            </Button>
          )}
        </Flex>
        <Table
          loading={loading}
          columns={[
            {
              label: locale.name,
              property: 'name',
              sortable: false,
              format: invite => invite.name,
            },
            {
              label: locale.email,
              property: 'email',
              sortable: false,
              format: invite => invite.email,
            },
            {
              label: locale.created_at,
              property: 'accountCreatedOn',
              sortable: false,
              format: invite => formatDate(invite.created_at),
            },
            {
              label: locale.role,
              property: 'role',
              sortable: false,
              format: invite => locale[invite.role],
            },
            {
              label: locale.status,
              property: 'status',
              sortable: false,
              format: invite => (
                <Box>
                  <Button
                    background={
                      invite.status === 'active'
                        ? theme.colors.dirtyBlue
                        : theme.colors.brand
                    }
                    onClick={() =>
                      toggleInviteStatusHandler(invite.status, invite.id)
                    }
                  >
                    {locale.statusObj[invite.status]}
                  </Button>
                </Box>
              ),
            },
            {
              label: locale.actions,
              format: item => {
                return (
                  <Box>
                    <Button filled onClick={() => deleteInviteHandler(item.id)}>
                      eliminar
                    </Button>
                    {item.status === 'active' && (
                      <Button
                        outline
                        padding=".7rem"
                        margin="0 .5rem"
                        link
                        target="_blank"
                        href={
                          process.env.REACT_APP_FRONTEND_URL +
                          (item.role === 'user'
                            ? '/register-team-user/'
                            : '/register-team-admin/') +
                          item.id
                        }
                      >
                        Criar User
                      </Button>
                    )}
                  </Box>
                );
              },
            },
          ]}
          data={data.invite}
        />
      </Box>
    </Wrapper>
  );
};

export default BrokerDetail;
