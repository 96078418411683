import React from 'react';

import {
  Badge,
  Button,
  Box,
  Flex,
  Heading,
  StickyGrid,
  locale,
  ItemStatus
} from '@innovago/ui';

const FormHeader = ({title, formik, schedule, showBadge}) => {
  return (
    <StickyGrid>
      <Box margin="2rem 0">
        {showBadge && (
          <Badge
            label={locale[formik.values.status]}
            type={formik.values.status}
          />
        )}
        <Heading padding=".5rem 0" level="1">
          {title}
        </Heading>
      </Box>
      <Flex>
        <Button
          link
          type="button"
          onClick={() => {
            formik.setFieldValue('status', ItemStatus.Draft);
            formik.handleSubmit();
          }}
        >
          {locale.saveDraft}
        </Button>
        <Button
          type="button"
          filled
          onClick={() => {
            formik.setFieldValue('status', ItemStatus.Published);
            formik.handleSubmit();
          }}
        >
          {schedule ? locale.schedule : locale.publish}
        </Button>
      </Flex>
    </StickyGrid>
  );
};

export default FormHeader;
