import gql from 'graphql-tag';

export const uploadFile = gql`
  mutation uploadFile($objects: UploadFileInput!) {
    uploadFile(objects: $objects) {
      id
    }
  }
`;

export const createContent = gql`
  mutation createContent($objects: [content_insert_input!]!) {
    insert_content(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const updateContent = gql`
  mutation updateContent($id: Int, $set: content_set_input) {
    update_content(_set: $set, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const deleteContent = gql`
  mutation deleteContent($id: Int) {
    delete_content(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const approveBroker = gql`
  mutation approve_broker($data: ApproveBrokerInput!) {
    approveBroker(data: $data) {
      id
    }
  }
`;

export const rejectBroker = gql`
  mutation rejectBroker($id: ID!, $observations: String!) {
    rejectBroker(data: {id: $id, observations: $observations}) {
      id
      name
    }
  }
`;

export const InsertGroup = gql`
  mutation insert_group($title: String!) {
    insert_group(objects: {title: $title}) {
      returning {
        id
      }
    }
  }
`;

export const DeleteGroup = gql`
  mutation delete_group($id: Int!) {
    delete_group(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const rejectBrokerPermanently = gql`
  mutation rejectBroker($id: ID!, $observations: String!) {
    rejectBroker(
      data: {id: $id, observations: $observations, permanent: true}
    ) {
      id
      name
    }
  }
`;

export const activateBroker = gql`
  mutation activateBroker($id: ID!) {
    activateBroker(data: {id: $id}) {
      id
    }
  }
`;

export const inactivateBroker = gql`
  mutation deactivateBroker($id: ID!) {
    deactivateBroker(data: {id: $id}) {
      id
    }
  }
`;

export const updateBrokerStatus = gql`
  mutation updateBrokerStatus($id: Int, $status: broker_status_enum!) {
    update_broker(_set: {status: $status}, where: {id: {_eq: $id}}) {
      returning {
        id
      }
    }
  }
`;

export const setBrokerEmail = gql`
  mutation setBrokerEmail($id: Int, $email: String!) {
    update_broker(_set: {email: $email}, where: {id: {_eq: $id}}) {
      returning {
        id
      }
    }
  }
`;

export const updateInviteStatus = gql`
  mutation updateInviteStatus($id: uuid!, $status: invite_status_enum!) {
    update_invite(_set: {status: $status}, where: {id: {_eq: $id}}) {
      returning {
        id
      }
    }
  }
`;

export const createInvite = gql`
  mutation createInvite($data: CreateInviteInput!) {
    createInvite(data: $data) {
      id
    }
  }
`;

export const insertInvite = gql`
  mutation insert_invite(
    $broker_id: Int
    $email: String
    $name: String
    $status: invite_status_enum
    $role: invite_role_enum
  ) {
    insert_invite(
      objects: {
        broker_id: $broker_id
        email: $email
        name: $name
        status: $status
        role: $role
      }
    ) {
      affected_rows
    }
  }
`;

export const updateUser = gql`
  mutation updateUser($id: Int, $data: user_set_input) {
    update_user(_set: $data, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const activateUser = gql`
  mutation activateUser($id: ID!) {
    activateUser(data: {id: $id}) {
      status
    }
  }
`;

export const deactivateUser = gql`
  mutation deactivateUser($id: ID!) {
    deactivateUser(data: {id: $id}) {
      status
    }
  }
`;

export const createProduct = gql`
  mutation createProduct($objects: [product_insert_input!]!) {
    insert_product(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const updateProduct = gql`
  mutation updateProduct($set: product_set_input, $id: Int) {
    update_product(_set: $set, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const deleteProduct = gql`
  mutation deleteProduct($id: Int) {
    delete_product(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const createTraining = gql`
  mutation createTraining($objects: [training_insert_input!]!) {
    insert_training(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const updateTraining = gql`
  mutation updateTraining($set: training_set_input, $id: Int) {
    update_training(_set: $set, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const deleteTraining = gql`
  mutation deleteTraining($id: Int) {
    delete_training(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const deleteTrainingCategories = gql`
  mutation deleteTrainingCategories($id: Int) {
    delete_training_category(where: {training_id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const insertTrainingCategories = gql`
  mutation insertTrainingCategories(
    $objects: [training_category_insert_input!]!
  ) {
    insert_training_category(objects: $objects) {
      affected_rows
    }
  }
`;

export const insertCategory = gql`
  mutation insertCategory($name: String!) {
    insert_category(objects: {name: $name}) {
      affected_rows
    }
  }
`;

export const deleteCategory = gql`
  mutation deleteCategory($id: Int!) {
    delete_category(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const requestPasswordReset = gql`
  mutation requestPasswordReset($data: RequestPasswordResetInput!) {
    requestPasswordReset(data: $data)
  }
`;

export const resetPassword = gql`
  mutation resetPassword($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      id
    }
  }
`;

export const deleteInvite = gql`
  mutation deleteInvite($id: uuid!) {
    delete_invite(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const deleteThumbnail = gql`
  mutation deleteThumbnail($id: Int!) {
    delete_attachment(where: {id: {_eq: $id}}) {
      returning {
        id
      }
    }
  }
`;

export const deletePresentation = gql`
  mutation deletePresentation($id: Int!, $trainingID: Int!) {
    delete_training_attachment(
      where: {id: {_eq: $id}, training_id: {_eq: $trainingID}}
    ) {
      returning {
        id
      }
    }
  }
`;

export const insertSetting = gql`
  mutation insert_setting($objects: [setting_insert_input!]!) {
    insert_setting(
      objects: $objects
      on_conflict: {
        constraint: PK_27923d152bbf82683ab795d5476
        update_columns: value
      }
    ) {
      affected_rows
      returning {
        name
        value
      }
    }
  }
`;

export const refreshProducts = gql`
  mutation refreshProducts {
    refreshProducts {
      success
      error
    }
  }
`;

export const promoteUser = gql`
  mutation promoteUserToTeamAdmin($id: ID!) {
    promoteUserToTeamAdmin(data: {id: $id}) {
      id
    }
  }
`;

export const demoteUser = gql`
  mutation promoteTeamAdminToUser($id: ID!) {
    promoteTeamAdminToUser(data: {id: $id}) {
      id
    }
  }
`;

export const updatePresence = gql`
  mutation updatePresence($id: Int!) {
    update_presence(_set: {status: "pending"}, where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`;

export const updateSubmission = gql`
  mutation updateSubmission($allowSubmission: Boolean, $id: Int!) {
    update_broker(
      _set: {allow_submission: $allowSubmission}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`;
