import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Delimiter from '@editorjs/delimiter';
import List from '@editorjs/list';
import styled from 'styled-components';

import {Accordion, FileList, FormPlaceholder} from '@innovago/editor';
import {locale, theme} from '@innovago/ui';

import {uploadFile as uploadFileFetch} from '../../utils/uploadFile';

const EditorStyled = styled.div`
  color: ${theme.colors.darkBlue};
  font-family: ${theme.fonts.lato};
  max-width: 650px;

  .ce-settings__default-zone {
    min-width: 115px;
  }

  .ce-toolbar .ce-toolbox {
    background-color: #fff;
  }

  .ce-paragraph[data-placeholder]:empty::before {
    opacity: 1;
  }

  .ce-delimiter:before {
    content: '--- Quebra de Coluna ---';
    font-size: 0.7rem;
  }
`;

function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = () => {
      reject();
    };
    fr.readAsDataURL(file);
  });
}

// const toolsList = [
//   'header',
//   'list',
//   'accordion',
//   'fileList',
//   'delimiter',
//   'formPlaceholder'
// ];

const Editor = memo(
  ({
    uploadDetails,
    data = {},
    tools = [],
    holderCtn = "editor-js",
    onChange = () => {},
    onReady = () => {},
    onError = () => {}
  }) => {
    const holder = holderCtn;

    const defaultTools = {
      header: {
        class: Header,
        config: {
          placeholder: locale.editorPlaceholder
        }
      },
      list: {
        class: List,
        inlineToolbar: true
      },
      accordion: {
        class: Accordion,
        config: {
          addLabel: locale.add,
          placeholderTitle: locale.title,
          placeholderLabel: locale.label,
          placeholderText: locale.editorPlaceholder
        }
      },
      fileList: {
        class: FileList,
        config: {
          addLabel: locale.add,
          placeholderTitle: locale.fileListTitle,
          placeholderLabel: locale.fileListLabel,
          placeholderFile: locale.fileListChoose,
          errorUpload: locale.errorUpload,
          uploader: {
            uploadByFile(file) {
              return uploadFile(file)
                .then(response => response)
                .catch(err => console.log('error uploading', err));
            }
          }
        }
      },
      formPlaceholder: {
        class: FormPlaceholder,
        placeholderTitle: locale.fileListTitle,
        placeholderLabel: locale.fileListLabel
      },
      delimiter: {
        class: Delimiter
      }
    };

    const editorTools = tools.length
      ? Object.keys(defaultTools)
          .filter(key => tools.includes(key))
          .reduce((result, key) => ({...result, [key]: defaultTools[key]}), {})
      : defaultTools;

    async function uploadFile(file) {
      const error = {
        success: 0
      };

      try {
        const fileData = await fileToDataUrl(file);
        const data = await uploadFileFetch({
          ...uploadDetails,
          media: {
            fileName: file.name,
            type: file.type,
            file: fileData
          }
        });

        return {
          success: 1,
          file: {
            url: data.url,
            name: file.name,
            id: data.id
          }
        };
      } catch (err) {
        throw error;
      }
    }

    useEffect(() => {
      let editor;

      editor = new EditorJS({
        placeholder: locale.editorPlaceholder,
        holder,
        tools: editorTools,
        data,
        onReady,
        onChange: async () => {
          try {
            const data = await editor.save();

            onChange(data);
          } catch (err) {
            console.log('error editor', err);
            onError(locale.errorSaving);
          }
        }
      });

      return () => {
        if (editor && typeof editor.destroy === 'function') {
          editor.destroy();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <EditorStyled id={holder} />;
  }
);

Editor.propTypes = {
  uploadDetails: PropTypes.shape(
    {
      callBackAction: PropTypes.string.isRequired,
      callBackId: PropTypes.number.isRequired
    }.isRequired
  ),
  data: PropTypes.object,
  tools: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onReady: PropTypes.func,
  onError: PropTypes.func
};

export default Editor;
