import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Flex,
  Button,
  Heading,
  Loader,
  locale,
  theme,
  FormInput,
  FormSelect,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(locale.required),
  name: Yup.string().required(locale.required),
  role: Yup.string().required(locale.required),
});

export const CreateInvite = props => {
  const {
    broker,
    createInviteStatus,
    insertInviteStatus,
    teamAdmins,
    setBrokerEmailStatus,
  } = props;

  const formik = useFormik({
    initialValues: {email: '', name: '', role: 'user'},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      const inviteData = {
        brokerId: broker.id,
        name: values.name,
        email: values.email.toLowerCase(),
        role: values.role,
      };
      if (broker.status === 'active') {
        createInviteStatus({
          variables: {
            data: inviteData,
          },
        });
      } else {
        insertInviteStatus({
          variables: {
            broker_id: broker.id,
            name: values.name,
            email: values.email.toLowerCase(),
            role: values.role,
            status: 'inactive',
          },
        });
      }

      if (teamAdmins === 0 && values.role === 'team_admin') {
        setBrokerEmailStatus({
          variables: {
            id: broker.id,
            email: values.email.toLowerCase(),
          },
        });
      }
    },
  });

  if (!broker) {
    return <Loader />;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading
        level="2"
        font={theme.fonts.mont}
        weight="500"
        size="2rem"
        padding="0 0 2rem"
      >
        {locale.invite} {locale.user}
      </Heading>

      <FormInput
        type="email"
        name="email"
        label={locale.email}
        formik={formik}
      ></FormInput>
      <FormInput
        type="text"
        name="name"
        label={locale.name}
        formik={formik}
      ></FormInput>
      <FormSelect
        formik={formik}
        label={locale.role}
        name="role"
        options={[
          {label: locale.user, value: 'user'},
          {label: locale.team_admin, value: 'team_admin'},
        ]}
        allowEmpty={false}
      />
      <Flex justify="flex-end">
        <Button type="submit" filled>
          {locale.invite}
        </Button>
      </Flex>
    </form>
  );
};
