import React, {useEffect} from 'react';
import {navigate, useQueryParams} from 'hookrouter';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {getContent} from '../../graphql/queries';

import {
  Loader,
  NotificationContainer,
  useNotification,
  locale,
} from '@innovago/ui';

import ContentForm from '../../components/ContentForm';
import {
  updateContent as updateContentMutation,
  deleteThumbnail as deleteThumbnailMutation,
} from '../../graphql/mutations';

import {formatDataOnReceive} from '../../utils/data';

const ContentEdit = ({id, type}) => {
  const [queryParams] = useQueryParams();
  const [notifications, notify] = useNotification();
  const {data, loading} = useQuery(getContent, {
    variables: {
      id,
    },
  });
  const [updateContent] = useMutation(updateContentMutation, {
    onCompleted: () => notify(locale.savedSuccessfully, 'success'),
    onError: () => notify(locale.errorSaving),
  });
  const [deleteThumbnail] = useMutation(deleteThumbnailMutation, {
    onCompleted: () => notify(locale.imageDeleted, 'success'),
    onError: () => notify(locale.errorDeleting),
    refetchQueries: ['getContent'],
  });

  useEffect(() => {
    if (queryParams.created) {
      notify(locale.savedSuccessfully, 'success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  function handleUpdate({id, __typename, ...rest}) {
    updateContent({
      variables: {id, set: rest},
    });
  }

  function handleDelete(id) {
    return deleteThumbnail({
      variables: {
        id: id,
      },
    });
  }

  if (loading) {
    return <Loader />;
  }

  if (!data || (data && !data.content_by_pk)) {
    navigate('/404');
    return null;
  }

  return (
    <>
      <NotificationContainer {...notifications} />
      <ContentForm
        title={locale.edit + ' ' + locale[type].singular}
        initialValues={formatDataOnReceive(data.content_by_pk)}
        onSubmit={handleUpdate}
        onDeleteThumbnail={handleDelete}
        editMode
      />
    </>
  );
};

export default ContentEdit;
