import Uploader from './uploader';
import './index.css';

const LOADER_DELAY = 500;

export default class FileList {
  constructor({config, data, api}) {
    this.api = api;

    this._CSS = {
      block: this.api.styles.block,
      loader: this.api.styles.loader,
      wrapper: 'ce-fileList',
      title: 'ce-title',
      item: 'ce-item',
      button: 'ce-button',
      input: 'ce-input',
      name: 'ce-name',
      file: 'ce-file',
      actions: 'ce-actions',
      up: 'ce-up',
      down: 'ce-down',
      delete: 'ce-delete'
    };

    this._placeholderTitle = config.placeholderTitle
      ? config.placeholderTitle
      : 'File list title';
    this._placeholderLabel = config.placeholderLabel
      ? config.placeholderLabel
      : 'File label';
    this._placeholderFile = config.placeholderFile
      ? config.placeholderFile
      : 'select a file';
    this._errorUpload = config.errorUpload
      ? config.errorUpload
      : 'Can not upload an image, try another';
    this._addLabel = config.addLabel ? config.addLabel : 'Add file';

    this.data = {
      title: data.title || '',
      items: data.items || []
    };

    this.nodes = {
      title: null,
      items: [],
      button: null
    };

    /**
     * Module for image files uploading
     */
    this.uploader = new Uploader({
      config: {
        uploader: config.uploader || undefined
      },
      onUpload: response => this._onUpload(response),
      onError: error => this._uploadingFailed(error)
    });
  }

  static get toolbox() {
    return {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14"><path d="M11.4 3.5L7.9 0 7.5 0l-.4.1-3.5 3.4c-.1.1-.1.3 0 .4 0 .2.2.3.4.3h2v5.4c0 .2.2.4.5.4h2c.3 0 .5-.2.5-.4V4.2h2c.2 0 .4-.1.5-.3s0-.3-.1-.4z"/><path d="M13 9v3H2V9H0v4c0 .6.4 1 1 1h13c.6 0 1-.4 1-1V9z"/></svg>',
      title: 'Files'
    };
  }

  render() {
    const wrapper = document.createElement('div');
    const title = this._createEl(
      [this._CSS.block, this._CSS.title],
      this._placeholderTitle,
      this.data.title,
      'h3'
    );
    const button = document.createElement('button');

    wrapper.classList.add(this._CSS.wrapper);

    button.classList.add(this._CSS.button);
    button.setAttribute('type', 'button');
    button.textContent = this._addLabel;

    wrapper.appendChild(title);
    wrapper.appendChild(button);

    this.nodes.wrapper = wrapper;
    this.nodes.title = title;
    this.nodes.button = button;

    this.data.items.forEach(item => this._addItem(item));

    button.addEventListener('click', () => this._addItem());

    return wrapper;
  }

  save() {
    this.data = {
      title: this.nodes.title.innerHTML,
      items: this.nodes.items.map(item => ({
        label: item.querySelector(`.${this._CSS.name}`).innerHTML,
        name: item.querySelector(`.${this._CSS.file}`).innerHTML,
        file: item.querySelector(`.${this._CSS.file}`).dataset.file,
        id: item.querySelector(`.${this._CSS.file}`).dataset.id
      }))
    };

    return this.data;
  }

  _addLoader(el) {
    el.classList.add(this._CSS.loader);
  }

  _addItem(data) {
    const wrapper = document.createElement('div');

    wrapper.classList.add(this._CSS.item);

    wrapper.appendChild(
      this._createEl(
        [this._CSS.input, this._CSS.name],
        this._placeholderLabel,
        data && data.label
      )
    );

    this.nodes.wrapper.insertBefore(wrapper, this.nodes.button);
    this.nodes.items.push(wrapper);

    this._setActions(wrapper);

    wrapper.appendChild(this._createFile([this._CSS.file], data));
  }

  _arrayMove(arr, oldI, newI) {
    const element = arr[oldI];

    arr.splice(oldI, 1);
    arr.splice(newI, 0, element);

    return arr;
  }

  _createEl(cssClasses, placeholder, content, tagName = 'div') {
    const el = document.createElement(tagName);

    el.classList.add(...cssClasses);
    el.dataset.placeholder = placeholder;
    el.innerHTML = content || '';
    el.contentEditable = true;
    el.dataset.gramm = false;

    return el;
  }

  _createFile(cssClasses, data) {
    const el = document.createElement('div');

    el.classList.add(...cssClasses);
    el.innerHTML = this._placeholderFile;

    el.addEventListener('click', () => {
      this.uploader.uploadSelectedFile({
        onPreview: () => this._addLoader(el)
      });
    });

    this._setFile(el, data);

    return el;
  }

  _onUpload(response) {
    if (response.success && response.file) {
      const fileEl = this.nodes.wrapper.querySelector(`.${this._CSS.loader}`);

      this._stopLoading(fileEl);
      this._setFile(fileEl, response.file);
    } else {
      this._uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  _setActions(el) {
    const actions = document.createElement('div');
    const deleteButton = document.createElement('button');
    const upButton = document.createElement('button');
    const downButton = document.createElement('button');

    deleteButton.classList.add(this._CSS.delete);
    deleteButton.setAttribute('type', 'button');
    upButton.classList.add(this._CSS.up);
    upButton.setAttribute('type', 'button');
    downButton.classList.add(this._CSS.down);
    downButton.setAttribute('type', 'button');

    actions.appendChild(upButton);
    actions.appendChild(downButton);
    actions.appendChild(deleteButton);

    actions.classList.add(this._CSS.actions);
    el.appendChild(actions);

    upButton.addEventListener('click', () => {
      const index = this.nodes.items.indexOf(el);
      const prev = this.nodes.items[index - 1];

      if (prev) {
        this.nodes.wrapper.insertBefore(el, prev);
      }

      this.nodes.items = this._arrayMove(this.nodes.items, index, index - 1);
    });

    downButton.addEventListener('click', () => {
      const index = this.nodes.items.indexOf(el);
      const next = this.nodes.items[index + 2];

      if (next) {
        this.nodes.wrapper.insertBefore(el, next);
      } else {
        this.nodes.wrapper.insertBefore(el, this.nodes.button);
      }

      this.nodes.items = this._arrayMove(this.nodes.items, index, index + 1);
    });

    deleteButton.addEventListener('click', e => {
      e.stopPropagation();

      el.remove();
      this.nodes.items = this.nodes.items.filter(item => item !== el);
      this.nodes.button.focus();
    });
  }

  _setFile(el, file) {
    if (file) {
      el.innerHTML = file.name;
      el.dataset.file = file.url;
      el.dataset.id = file.id;
    } else {
      el.innerHTML = this._placeholderFile;
      delete el.dataset.file;
      delete el.dataset.id;
    }
  }

  _stopLoading(el) {
    setTimeout(() => {
      el.classList.remove(this._CSS.loader);
    }, LOADER_DELAY);
  }

  _uploadingFailed(errorMessage) {
    console.log('Files Tool: uploading failed because of', errorMessage);
    const fileEl = this.nodes.wrapper.querySelector(`.${this._CSS.loader}`);

    this._stopLoading(fileEl);
    this._setFile(fileEl);

    this.api.notifier.show({
      message: this._errorUpload,
      style: 'error'
    });
  }
}
