import React, {useState} from 'react';
import {navigate} from 'hookrouter';
import {useMutation} from '@apollo/react-hooks';

import {
  NotificationContainer,
  useNotification,
  formatDate,
  ItemStatus,
  locale
} from '@innovago/ui';

import {ContentType} from '../../config/content';
import {
  createContent as createContentMutation,
  updateContent as updateContentMutation
} from '../../graphql/mutations';

import ContentForm from '../../components/ContentForm';

const ContentNew = ({type}) => {
  const [id, setId] = useState(null);
  const [notifications, notify] = useNotification();
  const [createContent] = useMutation(createContentMutation, {
    onCompleted: ({insert_content: {returning}}) => setId(returning[0].id),
    onError: () => notify(locale.errorSaving)
  });
  const [updateContent] = useMutation(updateContentMutation, {
    onCompleted: () => {
      navigate(`/${type}/${id}/edit`, true, {created: true});
    },
    onError: () => {
      notify(locale.errorSaving);
    }
  });

  function handleCreate({id, ...values}) {
    createContent({
      variables: {objects: {...values}}
    });
  }

  function handleUpdate({id, __typename, ...rest}) {
    updateContent({
      variables: {id, set: rest}
    });
  }
  if (!Object.values(ContentType).includes(type)) {
    navigate('/404');
    return null;
  }

  return (
    <>
      <NotificationContainer {...notifications} />
      <ContentForm
        title={locale.create + ' ' + locale[type].singular}
        initialValues={{
          id,
          title: '',
          body: null,
          publish_at: formatDate(new Date()),
          status: ItemStatus.Draft,
          type,
          slug: ''
        }}
        onSubmit={values => {
          values.id ? handleUpdate(values) : handleCreate(values);
        }}
      />
    </>
  );
};

export default ContentNew;
