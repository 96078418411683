import React, {useState, useEffect} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import styled from 'styled-components';
import {getSortedProducts} from '../../graphql/queries';
import {updateProduct as updateProductMutation} from '../../graphql/mutations';
import {
  sortableContainer,
  sortableElement,
  arrayMove,
} from 'react-sortable-hoc';

import {
  Heading,
  Box,
  Grid3,
  StickyGrid,
  Wrapper,
  Button,
  NotificationContainer,
  useNotification,
  Loader,
  theme,
} from '@innovago/ui';

const Li = styled.li`
  font: ${theme.fonts.mont};
  font-size: 0.875rem;
  color: ${theme.colors.darkBlue};
  line-height: 2rem;
  list-style: none;
  margin: 0 0 1px;
  padding: 0 1rem;
  background: ${theme.colors.cloudyBlue2};
  white-space: nowrap;
  cursor: move;
`;

const SortableItem = sortableElement(({value}) => <Li>{value}</Li>);

const SortableContainer = sortableContainer(({children}) => {
  return <div>{children}</div>;
});

const MenuOrder = () => {
  const [notifications, notify] = useNotification();
  const [products, setProducts] = useState(false);

  const {data, loading} = useQuery(getSortedProducts, {
    fetchPolicy: 'cache-and-network',
    onError: () => notify(),
  });

  const [updateProduct] = useMutation(updateProductMutation, {
    onError: () => notify(),
  });

  useEffect(() => {
    setProducts(data);
  }, [data]);

  const onSortEnd = ({oldIndex, newIndex, collection}) => {
    const type = collection[0].type;
    const newOrder = {...products};
    newOrder[type] = arrayMove(newOrder[type], oldIndex, newIndex);
    setProducts(newOrder);
  };

  const handleSave = () => {
    products.patrimony.forEach((product, index) => {
      updateProduct({
        variables: {
          set: {
            order: index,
          },
          id: product.id,
        },
      });
    });

    products.responsability.forEach((product, index) => {
      updateProduct({
        variables: {
          set: {
            order: index,
          },
          id: product.id,
        },
      });
    });

    products.professional.forEach((product, index) => {
      updateProduct({
        variables: {
          set: {
            order: index,
          },
          id: product.id,
        },
      });
    });
  };

  if (loading || !products) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          Ordem no menu
        </Heading>
      </StickyGrid>
      <Grid3 align="flex-start">
        <SortableContainer onSortEnd={onSortEnd}>
          <Box>
            <ul>
              {products &&
                products.patrimony.map((item, i) => (
                  <SortableItem
                    key={item.id}
                    value={item.short_title ? item.short_title : item.title}
                    index={i}
                    collection={products.patrimony}
                  />
                ))}
            </ul>
          </Box>
        </SortableContainer>
        <SortableContainer onSortEnd={onSortEnd}>
          <Box>
            <ul>
              {products &&
                products.responsability.map((item, i) => (
                  <SortableItem
                    key={item.id}
                    value={item.short_title ? item.short_title : item.title}
                    index={i}
                    collection={products.responsability}
                  />
                ))}
            </ul>
          </Box>
        </SortableContainer>
        <SortableContainer onSortEnd={onSortEnd}>
          <Box>
            <ul>
              {products &&
                products.professional.map((item, i) => (
                  <SortableItem
                    key={item.id}
                    value={item.short_title ? item.short_title : item.title}
                    index={i}
                    collection={products.professional}
                  />
                ))}
            </ul>
          </Box>
        </SortableContainer>
      </Grid3>
      <Box>
        <Button filled onClick={() => handleSave()}>
          Gravar
        </Button>
      </Box>
    </Wrapper>
  );
};

export default MenuOrder;
