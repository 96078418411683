import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Heading,
  Loader,
  StickyGrid,
  Table,
  Wrapper,
  Box,
  Grid2,
  FormInput,
  FormCheckbox,
  NotificationContainer,
  useNotification,
  locale,
} from '@innovago/ui';

import {getGroups} from '../../graphql/queries';
import {
  InsertGroup as InsertGroupMutation,
  DeleteGroup as DeleteGroupMutation,
  updateProduct as UpdateProductMutation,
} from '../../graphql/mutations';

const validationSchema = Yup.object().shape({
  title: Yup.string().required(locale.required),
});

const Groups = () => {
  const [notifications, notify] = useNotification();
  const [productsID, setProductsID] = useState([]);

  const {data, loading} = useQuery(getGroups, {
    fetchPolicy: 'cache-and-network',
    onError: () => notify(),
  });

  const initialValues = {
    title: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      if (productsID.length > 0) {
        handleGroupInsert(values);
      } else {
        notify('Tem que escolher pelo menos 1 produto', 'error');
      }
    },
  });

  const [insertGroup] = useMutation(InsertGroupMutation, {
    onCompleted: data => {
      const groupID = data.insert_group.returning[0].id;
      productsID.forEach(productID => {
        updateProduct({
          refetchQueries: ['getGroups'],
          variables: {
            id: productID,
            set: {group_id: groupID},
          },
        });
      });
    },
    onError: () => notify(),
  });

  const [deleteGroup] = useMutation(DeleteGroupMutation, {
    onCompleted: data => {
      notify('O grupo foi apagado com sucesso', 'success');
    },
    onError: () => notify(),
  });

  const [updateProduct] = useMutation(UpdateProductMutation, {
    onCompleted: () => {
      setProductsID([]);
      notify('O Grupo foi criado com sucesso', 'success');
    },
    onError: () => notify(),
  });

  function handleDeleteGroup(id) {
    deleteGroup({
      refetchQueries: ['getGroups'],
      variables: {
        id: id,
      },
    });
  }

  function handleGroupInsert(values) {
    insertGroup({
      refetchQueries: ['getGroups'],
      variables: {
        title: values.title,
      },
    });
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          {locale.groups.plural}
        </Heading>
      </StickyGrid>
      <Table
        loading={loading}
        columns={[
          {
            label: locale.title,
            property: 'title',
            sortable: false,
            format: item => item.title,
          },
          {
            label: locale.product.plural,
            property: 'status',
            sortable: false,
            format: item => {
              const products = item.products.map(product => product.title);
              return products.join();
            },
          },
          {
            label: locale.actions,
            property: '',
            sortable: false,
            format: item => {
              return (
                <Button filled onClick={() => handleDeleteGroup(item.id)}>
                  Apagar Grupo
                </Button>
              );
            },
          },
        ]}
        data={data && data.group}
      />
      <Box>
        <Heading level="3" padding="0 0 1rem">
          Adicionar grupo
        </Heading>

        <Grid2 align="flex-start">
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <FormInput formik={formik} label="Nome do grupo" name="title" />
              <Button type="submit" filled>
                Criar grupo
              </Button>
            </form>
          </Box>
          <Box>
            <Heading level="4" size="1rem" padding="0 0 1rem">
              Produtos Escolhidos
            </Heading>
            {data.product.map(product => {
              return (
                <Box padding="0 0 .5rem" key={`product_${product.id}`}>
                  <FormCheckbox
                    name={`products_${product.id}`}
                    type="checkbox"
                    value={product.id}
                    label={product.title}
                    formik={formik}
                    onChange={e => {
                      const checked =
                        e.currentTarget.querySelector('input').checked;
                      const value =
                        e.currentTarget.querySelector('input').value;
                      const oldValues = productsID;
                      if (checked) {
                        const newArr = oldValues;
                        newArr.push(value);
                        setProductsID(newArr);
                      } else {
                        const newArr = oldValues.filter(product =>
                          product !== value ? product : null
                        );
                        setProductsID(newArr);
                      }
                    }}
                  />
                </Box>
              );
            })}
          </Box>
        </Grid2>
      </Box>
    </Wrapper>
  );
};

export default Groups;
