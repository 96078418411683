import React from 'react';
import {navigate} from 'hookrouter';
import {useMutation} from '@apollo/react-hooks';

import {
  NotificationContainer,
  useNotification,
  ItemStatus,
  locale,
} from '@innovago/ui';

import {
  createProduct as createProductMutation,
} from '../../graphql/mutations';

import ProductForm from '../../components/ProductForm';
import {ProductType} from '../../config/content';
import slugify from '@sindresorhus/slugify';

const ContentNew = ({type}) => {
  const [notifications, notify] = useNotification();
  const [createProduct] = useMutation(createProductMutation, {
    onCompleted: ({insert_product: {returning}}) => {
      navigate(`/product/${returning[0].id}/edit`, true, {created: true});
    },
    onError: (data) => {
      if (data.message.indexOf('IDX_8cfaf4a1e80806d58e3dbe6922') >= 0) {
        notify('Produto já existente. Experimente um titulo alternativo');
      } else {
        notify(locale.errorSaving);
      }
    },
  });

  function handleCreate({id, ...values}) {
    createProduct({
      variables: {objects: {...values, slug: slugify(values.title)}},
    });
  }

  return (
    <>
      <NotificationContainer {...notifications} />
      <ProductForm
        title={locale.create + ' ' + locale.product.singular}
        initialValues={{
          integration_code: '',
          title: '',
          short_title: '',
          has_simulator: false,
          body: null,
          slug: '',
          status: ItemStatus.Draft,
          type: ProductType.Patrimony,
        }}
        onSubmit={({integration_code, ...values}) => {
          Object.keys(values).forEach((k) => !values[k] && delete values[k]);

          if (!values['has_simulator']) values['has_simulator'] = false;

          return handleCreate(values);
        }}
      />
    </>
  );
};

export default ContentNew;
