import React from 'react';

import {Heading, Wrapper, locale} from '@innovago/ui';

const NotFound = () => (
  <Wrapper>
    <Heading padding="2rem 0" level="1">
      {locale.notFound}
    </Heading>
    {locale.notFoundInfo}
  </Wrapper>
);

export default NotFound;
