import './index.css';

export default class FormPlaceholder {
  constructor({config, data, api}) {
    this.api = api;

    this._CSS = {
      block: this.api.styles.block,
      loader: this.api.styles.loader,
      wrapper: 'ce-formplaceholder',
      title: 'ce-title',
      text: 'ce-text',
      info: 'ce-info'
    };

    this._placeholderTitle = config.placeholderTitle
      ? config.placeholderTitle
      : 'Form Title';
    this._placeholderLabel = config.placeholderLabel
      ? config.placeholderLabel
      : 'Form Label';

    this.data = {
      title: data.title || '',
      text: data.text || ''
    };

    this.nodes = {
      title: null,
      text: null
    };
  }

  static get toolbox() {
    return {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="16"><path d="M.6 13.3h.7V8.7H.6a.6.6 0 01-.6-.6V6.6c0-.3.3-.6.6-.6h3.5c.4 0 .7.3.7.6v6.7h.6c.3 0 .6.2.6.6v1.5c0 .3-.3.6-.6.6H.6a.6.6 0 01-.6-.6v-1.5c0-.4.3-.6.6-.6zM3 0a2.2 2.2 0 100 4.5A2.2 2.2 0 003 0z"/></svg>',
      title: 'Form'
    };
  }

  render() {
    const wrapper = document.createElement('div');
    const title = this._createEl(
      [this._CSS.block, this._CSS.title],
      this._placeholderTitle,
      this.data.title,
      'h3'
    );

    const text = this._createEl(
      [this._CSS.block, this._CSS.title],
      this._placeholderLabel,
      this.data.text
    );

    wrapper.classList.add(this._CSS.wrapper);
    wrapper.appendChild(title);
    wrapper.appendChild(text);

    this.nodes.wrapper = wrapper;
    this.nodes.title = title;
    this.nodes.text = text;

    return wrapper;
  }

  save() {
    this.data = {
      title: this.nodes.title.innerHTML,
      text: this.nodes.text.innerHTML
    };

    return this.data;
  }

  _addLoader(el) {
    el.classList.add(this._CSS.loader);
  }

  _arrayMove(arr, oldI, newI) {
    const element = arr[oldI];

    arr.splice(oldI, 1);
    arr.splice(newI, 0, element);

    return arr;
  }

  _createEl(cssClasses, placeholder, content, tagName = 'div') {
    const el = document.createElement(tagName);

    el.classList.add(...cssClasses);
    el.dataset.placeholder = placeholder;
    el.innerHTML = content || '';
    el.contentEditable = true;
    el.dataset.gramm = false;

    return el;
  }

  _createFile(cssClasses, data) {
    const el = document.createElement('div');

    el.classList.add(...cssClasses);
    el.innerHTML = this._placeholderFile;

    el.addEventListener('click', () => {
      this.uploader.uploadSelectedFile({
        onPreview: () => this._addLoader(el)
      });
    });

    this._setFile(el, data);

    return el;
  }

  _onUpload(response) {
    if (response.success && response.file) {
      const fileEl = this.nodes.wrapper.querySelector(`.${this._CSS.loader}`);

      this._stopLoading(fileEl);
      this._setFile(fileEl, response.file);
    } else {
      this._uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  _setActions(el) {
    const actions = document.createElement('div');
    const deleteButton = document.createElement('button');
    const upButton = document.createElement('button');
    const downButton = document.createElement('button');

    deleteButton.classList.add(this._CSS.delete);
    deleteButton.setAttribute('type', 'button');
    upButton.classList.add(this._CSS.up);
    upButton.setAttribute('type', 'button');
    downButton.classList.add(this._CSS.down);
    downButton.setAttribute('type', 'button');

    actions.appendChild(upButton);
    actions.appendChild(downButton);
    actions.appendChild(deleteButton);

    actions.classList.add(this._CSS.actions);
    el.appendChild(actions);

    upButton.addEventListener('click', () => {
      const index = this.nodes.items.indexOf(el);
      const prev = this.nodes.items[index - 1];

      if (prev) {
        this.nodes.wrapper.insertBefore(el, prev);
      }

      this.nodes.items = this._arrayMove(this.nodes.items, index, index - 1);
    });

    downButton.addEventListener('click', () => {
      const index = this.nodes.items.indexOf(el);
      const next = this.nodes.items[index + 2];

      if (next) {
        this.nodes.wrapper.insertBefore(el, next);
      } else {
        this.nodes.wrapper.insertBefore(el, this.nodes.button);
      }

      this.nodes.items = this._arrayMove(this.nodes.items, index, index + 1);
    });

    deleteButton.addEventListener('click', e => {
      e.stopPropagation();

      el.remove();
      this.nodes.items = this.nodes.items.filter(item => item !== el);
      this.nodes.button.focus();
    });
  }

  _setFile(el, file) {
    if (file) {
      el.innerHTML = file.name;
      el.dataset.file = file.url;
      el.dataset.id = file.id;
    } else {
      el.innerHTML = this._placeholderFile;
      delete el.dataset.file;
      delete el.dataset.id;
    }
  }

  _stopLoading(el) {
    setTimeout(() => {
      el.classList.remove(this._CSS.loader);
    }, LOADER_DELAY);
  }

  _uploadingFailed(errorMessage) {
    console.log('Files Tool: uploading failed because of', errorMessage);
    const fileEl = this.nodes.wrapper.querySelector(`.${this._CSS.loader}`);

    this._stopLoading(fileEl);
    this._setFile(fileEl);

    this.api.notifier.show({
      message: this._errorUpload,
      style: 'error'
    });
  }
}
