import React from 'react';
import Private from './components/Private';
import {Login} from './pages/Login';
import ChangePassword from './pages/ChangePassword';
import {Dashboard} from './pages/Dashboard';
import Trainings from './pages/Trainings';
import TrainingsCategories from './pages/TrainingsCategories';
import Settings from './pages/Settings';
import TrainingNew from './pages/TrainingNew';
import TrainingEdit from './pages/TrainingEdit';
import Products from './pages/Products';
import Groups from './pages/Groups';
import ProductNew from './pages/ProductNew';
import ProductEdit from './pages/ProductEdit';
import ContentList from './pages/ContentList';
import Simulators from './pages/Simulators';
import SimulatorEdit from './pages/SimulatorsEdit';
import BrokerList from './pages/BrokerList';
import BrokerDetail from './pages/BrokerDetail';
import ContentNew from './pages/ContentNew';
import ContentEdit from './pages/ContentEdit';
import NotFound from './pages/NotFound';
import Profile from './pages/Profile';
import MenuOrder from './pages/MenuOrder';

const routes = {
  '/404': () => <NotFound />,
  '/': () => <Login />,
  '/reset-password/:token': token => <ChangePassword token={token} />,
  '/profile': () => <Private component={Profile} />,
  '/brokers': () => <Private component={BrokerList} />,
  '/brokers/:id': id => <Private component={BrokerDetail} id={id} />,
  '/dashboard': () => <Private component={Dashboard} />,
  '/training': () => <Private component={Trainings} />,
  '/training-categories': () => <Private component={TrainingsCategories} />,
  '/groups': () => <Private component={Groups} />,
  '/training/new': () => <Private component={TrainingNew} />,
  '/training/:id/edit': ({id}) => <Private component={TrainingEdit} id={id} />,
  '/product': () => <Private component={Products} />,
  '/menu-order': () => <Private component={MenuOrder} />,
  '/product/new': () => <Private component={ProductNew} />,
  '/product/:id/edit': ({id}) => <Private component={ProductEdit} id={id} />,
  '/simulator': () => <Private component={Simulators} />,
  '/simulator/:id': ({id}) => <Private component={SimulatorEdit} id={id} />,
  '/settings': () => <Private component={Settings} />,
  '/:type': ({type}) => <Private component={ContentList} type={type} />,
  '/:type/new': ({type}) => <Private component={ContentNew} type={type} />,
  '/:type/:id/edit': ({id, type}) => (
    <Private component={ContentEdit} type={type} id={id} />
  ),
};

export default routes;
