import React from 'react';

import {
  ModelWithOverlay,
  Flex,
  Text,
  Button,
  locale,
  theme,
} from '@innovago/ui';

export const ConfirmationOverlay = props => {
  const {setConfirmationOverlay, inactivateBrokerHandler} = props;
  return (
    <ModelWithOverlay top="50%" hideClose={true}>
      <Flex align="center" justify="space-between">
        <Text color="brand">{locale.confirmInactivation}</Text>
        <Button
          onClick={() => {
            inactivateBrokerHandler();
          }}
          width="auto"
          background={theme.colors.success}
        >
          Sim
        </Button>
        <Button
          onClick={() => setConfirmationOverlay(false)}
          width="auto"
          background={theme.colors.error}
        >
          Não
        </Button>
      </Flex>
    </ModelWithOverlay>
  );
};
