import React, {useEffect, useState} from 'react';
import {navigate, useQueryParams} from 'hookrouter';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {getTraining} from '../../graphql/queries';

import {
  Loader,
  Wrapper,
  Box,
  Table,
  Button,
  Badge,
  NotificationContainer,
  useNotification,
  locale,
} from '@innovago/ui';

import TrainingForm from '../../components/TrainingForm';
import {
  updateTraining as updateTrainingMutation,
  updatePresence as updatePresenceMutation,
  deletePresentation as deletePresentationMutation,
  deleteTrainingCategories as deleteTrainingCategoriesMutation,
  insertTrainingCategories as insertTrainingCategoriesMutation,
} from '../../graphql/mutations';

const TrainingEdit = ({id}) => {
  const [presences, setPresences] = useState(false);
  const [attachments, setAttachments] = useState(false);
  const [cats, setCats] = useState([]);
  const [queryParams] = useQueryParams();
  const [notifications, notify] = useNotification();

  const {data, loading, error} = useQuery(getTraining, {
    variables: {
      id,
    },
    onCompleted: data => {
      setPresences(data.training[0].presences);
      setAttachments(data.training[0].attachments);
      delete data.training[0].presences;
      delete data.training[0].attachments;
    },
  });

  const [updateTraining] = useMutation(updateTrainingMutation, {
    onCompleted: () => deleteTrainingCategories(),
    onError: () => notify(locale.errorSaving),
  });

  const [deleteTrainingCategories] = useMutation(
    deleteTrainingCategoriesMutation,
    {
      variables: {id: id},
      onCompleted: () => insertTrainingCategories(),
      onError: () => notify(locale.errorSaving),
    }
  );

  const [insertTrainingCategories] = useMutation(
    insertTrainingCategoriesMutation,
    {
      variables: {
        objects: cats.map(cat => {
          return {
            category_id: cat,
            training_id: id,
          };
        }),
      },
      onCompleted: () => notify(locale.savedSuccessfully, 'success'),
      onError: () => notify(locale.errorSaving),
    }
  );

  const [updatePresence] = useMutation(updatePresenceMutation, {
    onCompleted: () =>
      notify('O pedido de certificado foi feito novamente', 'success'),
    onError: () => notify('Ocorreu um erro.', 'error'),
    refetchQueries: ['getTraining'],
  });

  const [deletePresentation] = useMutation(deletePresentationMutation, {
    onCompleted: () => notify(locale.imageDeleted, 'success'),
    onError: () => notify(locale.errorDeleting),
    refetchQueries: ['getTraining'],
  });

  useEffect(() => {
    if (queryParams.created) {
      notify(locale.savedSuccessfully, 'success');
    }
  }, [queryParams, notify]);

  function handleUpdate({id, __typename, ...rest}) {
    delete rest.training_categories;
    updateTraining({
      variables: {id, set: rest},
    });
  }

  function handleDelete(id, trainingID) {
    return deletePresentation({
      variables: {
        id: id,
        trainingID: trainingID,
      },
    });
  }

  useEffect(() => {
    if (
      data &&
      data.training &&
      data.training[0].training_categories.length > 0
    ) {
      setCats(
        data.training[0].training_categories.map(item => item.category_id)
      );
    } else {
      setCats([]);
    }
  }, [data]);

  if (loading || !cats) {
    return <Loader />;
  }

  if (!data || error) {
    navigate('/404');
    return null;
  }

  const copyToClipboard = () => {
    let status = false,
      range,
      sel;
    const table = document.createElement('table');
    table.id = 'table';

    data.training[0].presences.forEach(row => {
      switch (row.status) {
        case 'attended':
          status = 'Participou';
          break;
        case 'created':
          status = 'Inscrito';
          break;
        case 'subscribed':
          status = 'Inscrito';
          break;
        case 'pending':
          status = 'Em sincronização';
          break;
        case 'unavailable':
        default:
          status = 'Erro';
          break;
      }
      const tr = document.createElement('tr');
      table.appendChild(tr);

      const td1 = document.createElement('td');
      td1.innerHTML = row.user.forename;
      tr.appendChild(td1);

      const td2 = document.createElement('td');
      td2.innerHTML = row.user.email;
      tr.appendChild(td2);

      const td3 = document.createElement('td');
      td3.innerHTML = status;
      tr.appendChild(td3);

      const td4 = document.createElement('td');
      td4.innerHTML = row.certificate_url;
      tr.appendChild(td4);
    });

    document.body.appendChild(table);

    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(table);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(table);
        sel.addRange(range);
      }
      document.execCommand('copy');
    } else if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(table);
      range.select();
      range.execCommand('Copy');
    }

    notify('O conteúdo foi copiado para o clipboard', 'success');
    document.body.removeChild(table);
  };

  const retryCertificate = id => {
    updatePresence({
      variables: {id},
    });
  };

  return (
    <>
      <NotificationContainer {...notifications} />
      <TrainingForm
        title={locale.edit + ' ' + locale.training.singular}
        initialValues={data.training[0]}
        cats={cats}
        setCats={setCats}
        categories={data.category}
        attachments={attachments}
        onSubmit={handleUpdate}
        onDeletePresentation={handleDelete}
        editMode
      />
      <Wrapper>
        <Box margin="2rem 0 0">
          <Button margin="0 0 2rem 0" filled onClick={() => copyToClipboard()}>
            Copiar para o clipboard
          </Button>
        </Box>
      </Wrapper>
      {presences && (
        <Wrapper>
          <Table
            loading={loading}
            columns={[
              {
                label: locale.name,
                property: 'forename',
                sortable: false,
                format: item => item.user.forename,
              },
              {
                label: 'Email',
                property: 'email',
                sortable: false,
                format: item => item.user.email,
              },
              {
                label: locale.status,
                property: 'status',
                sortable: false,
                format: item => {
                  switch (item.status) {
                    case 'attended':
                      return (
                        <Badge
                          whitespace="nowrap"
                          label="Participou"
                          type="active"
                        />
                      );
                    case 'created':
                      return (
                        <Badge
                          whitespace="nowrap"
                          label="A inscrever"
                          type="pending"
                        />
                      );
                    case 'subscribed':
                      return (
                        <Badge
                          whitespace="nowrap"
                          label="Inscrito"
                          type="scheduled"
                        />
                      );
                    case 'pending':
                      return (
                        <Badge
                          whitespace="nowrap"
                          label="Em sincronização"
                          type="pending"
                        />
                      );
                    case 'unavailable':
                    default:
                      return (
                        <Badge
                          whitespace="nowrap"
                          label="Erro"
                          type="rejected"
                        />
                      );
                  }
                },
              },
              {
                label: 'Certificado',
                property: 'certificate_url',
                sortable: false,
                format: item => {
                  if (item.certificate_url) {
                    return (
                      <Button
                        filled
                        href={item.certificate_url}
                        target="_blank"
                      >
                        Download
                      </Button>
                    );
                  } else {
                    return false;
                  }
                },
              },
              {
                label: locale.actions,
                width: '100px',
                format: item => {
                  if (item.status === 'unavailable') {
                    return (
                      <Button filled onClick={() => retryCertificate(item.id)}>
                        Voltar a tentar
                      </Button>
                    );
                  }
                },
              },
            ]}
            data={presences}
          />
        </Wrapper>
      )}
    </>
  );
};

export default TrainingEdit;
