import React, {useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {navigate} from 'hookrouter';

import {AuthContext} from '../../contexts/AuthContext';

import RecoverPassword from './recoverPass';

import {
  Box,
  BgImage,
  Flex,
  Wrapper,
  Heading,
  Button,
  FormInput,
  FormPassword,
  FormCheckbox,
  NotificationContainer,
  useNotification,
  theme,
  locale,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(locale.required),
  password: Yup.string().required(locale.required),
});

export const Login = () => {
  const authContext = useContext(AuthContext);
  const [notifications, notify] = useNotification();
  const [modalVisibility, setModayVisibility] = useState(false);

  const formik = useFormik({
    initialValues: {email: '', password: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      try {
        const loginAvailable = await authContext.login(
          values.email.toLowerCase(),
          values.password
        );
        if (!loginAvailable) {
          notify('Não tem permissões para ver esta página', 'error');
        }
      } catch (err) {
        notify(locale.loginError);
      }
    },
  });

  const closeModal = () => {
    setModayVisibility(false);
  };

  if (authContext.isSignedIn()) {
    navigate('/dashboard', true);
  }

  return (
    <Box position="absolute" height="100%" width="100%">
      <BgImage image="./images/main-bg.jpg" aspectRatio={false} height="100%" width="100%" absolute>
        {modalVisibility && <RecoverPassword onClose={closeModal} />}
        <Wrapper>
          <NotificationContainer {...notifications} />
          <Flex align="flex-start" justify="space-between" height="100%">
            <Box columns="4" margin="15% 0 0 0" rounded></Box>
            <Box
              columns="4"
              margin="15% 0 0 0"
              backgroundColor="#fff"
              roundedTop
              padding="1.5rem 1rem"
            >
              <Heading
                level={2}
                color={theme.colors.brand}
                weight="500"
                size={'1.5rem'}
                padding={'0 0 2rem'}
              >
                Entrar no backoffice
              </Heading>
              <form onSubmit={formik.handleSubmit}>
                <FormInput
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder="luis@seguroscamoes.pt"
                  formik={formik}
                />
                <FormPassword
                  name="password"
                  label="Palavra-passe"
                  formik={formik}
                />
                <Flex justify="space-between" margin="0 0 2rem">
                  <FormCheckbox
                    name="remember"
                    label="Lembrar-me"
                    margin="0"
                    formik={formik}
                    disable={false}
                  />
                  <Button
                    size={'.875rem'}
                    color={theme.colors.brand}
                    align={'right'}
                    underline
                    link={true}
                    padding="0"
                    href="#"
                    onClick={() => setModayVisibility(true)}
                  >
                    Recuperar a palavra-passe
                  </Button>
                </Flex>
                <Button type="submit" filled>
                  Entrar
                </Button>
              </form>
            </Box>
          </Flex>
        </Wrapper>
      </BgImage>
    </Box>
  );
};
