import React, {useState} from 'react';
import slugify from '@sindresorhus/slugify';
import styled from 'styled-components';
import {Button, FormInput, locale, theme} from '@innovago/ui';

const {REACT_APP_FRONTEND_URL} = process.env;

const Wrapper = styled.div`
  align-items: center;
  color: ${theme.colors.darkGrey};
  display: flex;
  font-family: ${theme.fonts.mont};
  font-size: 0.8rem;
  height: 1.5rem;
  justify-content: start;
  line-height: 1.3;
  margin: -1rem 0 1.5rem;
`;

export const getContentUrl = (type, slug) => {
  const typeSlug = locale[type].route;

  return `${REACT_APP_FRONTEND_URL}/${typeSlug}/${slug}`;
};

const SlugField = ({name, formik, type, fallbackVal}) => {
  const field = formik.getFieldProps(name);
  const [open, setOpen] = useState(false);
  const [slug, setSlug] = useState(slugify(field.value || fallbackVal));
  const url = getContentUrl(
    type,
    open ? '' : slugify(field.value || fallbackVal)
  );
  const handleUpdate = function() {
    if (open) {
      setSlug(slugify(slug))
      formik.setFieldValue('slug', slugify(slug));
      formik.handleSubmit();
    }

    setOpen(!open);
  }

  return (
    <Wrapper>
      URL:{' '}
      <Button
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        color="inherit"
        link
      >
        {url}
      </Button>
      {open && (
        <FormInput
          name={name}
          formik={formik}
          onChange={e => {
            setSlug(e.target.value)
            formik.setFieldValue('slug', e.target.value);
          }}
          margin="0"
          width="auto"
          isSmall
        />
      )}
      <Button onClick={() => handleUpdate()} type="button" link>
        {open ? locale.save : locale.change}
      </Button>
    </Wrapper>
  );
};

export default SlugField;
