import React, {useState, useEffect} from 'react';
import Auth from '@aws-amplify/auth';

import {Loader} from '@innovago/ui';

const AUTHENTICATOR_AUTHSTATE = 'innovago-authState';

export const AuthContext = React.createContext({
  isSignedIn: () => false,
  login: () => {},
  logout: () => {},
  forgotPassword: () => {},
  forgotPasswordSubmit: () => {},
  user: null,
});

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [authState, setAuthState] = useState('signIn');
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    async function getCurrentUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();

        if (!user) {
          clearUser();
          setAuthChecked(true);
        } else {
          const refreshToken = (await Auth.currentSession()).getRefreshToken();
          await user.refreshSession(refreshToken, (err, session) => {
            if (err) {
              return err;
            }
            const token = session.idToken.getJwtToken();

            changeAuthState('signedIn');
            setToken(token);
            setUser(user);
            setAuthChecked(true);
          });
        }
      } catch (err) {
        let cachedAuthState = null;
        try {
          cachedAuthState = localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
        } catch (e) {
          console.log('Failed to get the auth state from local storage', e);
        }

        if (cachedAuthState === 'signedIn') {
          await logout();
        } else {
          changeAuthState('signIn');
        }

        setAuthChecked(true);
      }
    }

    if (!authChecked) {
      getCurrentUser();
    }
  });

  function isSignedIn() {
    return authState === 'signedIn';
  }

  function changeAuthState(state) {
    setAuthState(state);
    localStorage.setItem(AUTHENTICATOR_AUTHSTATE, state);
  }

  async function login(email, password) {
    try {
      const user = await Auth.signIn(email, password);

      if (
        user.signInUserSession.accessToken.payload['cognito:groups'][0] &&
        user.signInUserSession.accessToken.payload['cognito:groups'][0] ===
          'admins'
      ) {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          changeAuthState('newPassword');
          return;
        }

        const token = (await Auth.currentSession()).getIdToken().getJwtToken();
        setToken(token);
        changeAuthState('signedIn');
        return true;
      } else {
        return false;
      }
    } catch {
      throw new Error('Error with login');
    }
  }

  async function setToken(token) {
    localStorage.setItem('token', token);
  }

  async function logout() {
    await Auth.signOut();
    clearUser();
  }

  async function forgotPassword(email) {
    return await Auth.forgotPassword(email);
  }

  async function forgotPasswordSubmit(email, code, password) {
    return await Auth.forgotPasswordSubmit(email, code, password);
  }

  function clearUser() {
    localStorage.setItem('token', '');
    changeAuthState('signIn');
  }

  if (!authChecked) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{
        isSignedIn,
        login,
        logout,
        forgotPassword,
        forgotPasswordSubmit,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
