import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import {
  FormInput,
  FormFile,
  FormDate,
  FormCheckbox,
  Grid2,
  Wrapper,
  locale,
  Grid3,
} from '@innovago/ui';

import FormHeader from '../FormHeader';
import {uploadFile as uploadFileFetch} from '../../utils/uploadFile';

const GroupChecked = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 1rem;

  input {
    opacity: 0;
    position: absolute;
    display: none;
  }

  label {
    display: flex;
    margin: 0
    font-family: Lato;
    font-size: 0.875rem;
    position: relative;
    color: rgba(0,0,0,.87);
    padding: 0;

    &:before {
      display: inline-block;
      content: '';
      width: 18px;
      height: 18px;
      margin: 0 4px 0 0;
      border: 1px solid #03173d;
    }
  }

  input:checked + label {
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 3px;
      width: 12px;
      height: 12px;
      background-color: #03173d;
    }
  }
`;

const FormCheck = ({name, label, checked, onChange}) => {
  return (
    <GroupChecked>
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked ? 'checked' : false}
        onChange={onChange}
      />
      <label htmlFor={name}>{label}</label>
    </GroupChecked>
  );
};

function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = () => {
      reject();
    };
    fr.readAsDataURL(file);
  });
}

async function uploadFile(file, trainingID) {
  const error = {
    success: 0,
  };

  try {
    const fileData = await fileToDataUrl(file);
    const data = await uploadFileFetch({
      callBackAction: 'insertTrainingAttachments',
      callBackId: trainingID,
      media: {
        fieldName: 'presentation',
        fileName: file.name,
        type: file.type,
        file: fileData,
      },
    });

    return {
      success: 1,
      file: {
        id: data.id,
        size: file.size,
        url: data.url,
        name: file.name,
      },
    };
  } catch (err) {
    throw error;
  }
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
  city: Yup.string().required(locale.required),
  date: Yup.string().required(locale.required),
  hour: Yup.string().required(locale.required),
  teacher: Yup.string().required(locale.required),
  duration: Yup.string().required(locale.required),
  link: Yup.string().nullable(),
});

const TrainingForm = ({
  title,
  initialValues,
  categories,
  attachments,
  trainingID,
  cats,
  setCats,
  editMode,
  onSubmit = () => {},
  onDeletePresentation = () => {},
}) => {
  const [saved, setSaved] = useState(initialValues.id !== null);

  const [presentation, setPresentation] = useState({
    id: null,
    name: 'presentation',
    size: 0,
    label: 'Apresentação do Webinar (pdf)',
    file: null,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      onSubmit({...values, id: initialValues.id});
    },
  });

  useEffect(() => {
    if (!initialValues.id && formik.values.body && !saved) {
      formik.handleSubmit();
      setSaved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.body, initialValues, attachments, saved]);

  useEffect(() => {
    if (attachments && attachments.length > 0) {
      setPresentation({
        id: attachments[0].id,
        name: 'presentation',
        size: attachments[0].file_size,
        filename: attachments[0].file_name,
        file: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const onPresentationRemove = async id => {
    const deleteStatus = await onDeletePresentation(id, initialValues.id);

    if (deleteStatus.data.delete_training_attachment.returning.length > 0) {
      setPresentation({
        id: null,
        name: 'presentation',
        size: 0,
        label: 'Apresentação do Webinar (pdf)',
        file: null,
      });
    }
  };

  async function onPresentationChange(e) {
    const uploadedFile = e.target.files[0];
    const thumb = await uploadFile(uploadedFile, formik.values.id);

    setPresentation({
      id: thumb.file.id,
      name: 'presentation',
      size: thumb.file.size,
      label: 'Apresentação do Webinar (pdf)',
      filename: thumb.file.name,
      file: thumb.file.url,
    });
  }

  const handleCategoryChange = id => {
    const exists = cats.some(element => element === id);
    if (exists) {
      setCats(cats.filter(cat => cat !== id));
    } else {
      setCats([...cats, id]);
    }
  };

  return (
    <Wrapper
      as="form"
      onSubmit={formik.handleSubmit}
      style={{overflow: 'initial'}}
    >
      <FormHeader title={title} formik={formik} showBadge={editMode} />
      <Grid2>
        <FormInput
          type="text"
          name="name"
          label={locale.title}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="teacher"
          label={locale.trainer}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="city"
          label={locale.place}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="link"
          label={locale.registrationLink}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="conference_id"
          label={locale.conference_id}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="conference_video"
          label={locale.video_id}
          formik={formik}
          width="100%"
        />
        <FormInput
          type="text"
          name="duration"
          label={locale.duration}
          formik={formik}
          width="100%"
        />
        <FormCheckbox
          name="tutorial"
          label={locale.tutorial}
          margin="0"
          formik={formik}
          disable={false}
        />
      </Grid2>
      <Grid2 margin="2rem 0 0 0" overflow="initial">
        <FormDate
          width="100%"
          formik={formik}
          name="date"
          label={locale.date}
        />
        <FormInput
          type="text"
          name="hour"
          label={locale.hour + ' (Horas de Portugal Continental)'}
          width="100%"
          formik={formik}
        />
      </Grid2>
      <Grid2>
        <FormFile
          key="presentation"
          name="presentation"
          data={presentation}
          type="file"
          required
          formik={formik}
          onChange={onPresentationChange}
          onRemove={() => onPresentationRemove(presentation.id)}
        />
      </Grid2>
      {categories && (
        <Grid3 margin="1rem 0" padding="1.5rem" backgroundColor="#eee">
          {categories.map((category, index) => {
            const marked = cats.filter(cat => cat === category.id);
            const checked = marked.length > 0 ? true : false;

            return (
              <FormCheck
                key={category.id}
                value={category.id}
                checked={checked}
                label={category.name}
                name={category.id}
                onChange={e => handleCategoryChange(category.id)}
              />
            );
          })}
        </Grid3>
      )}
    </Wrapper>
  );
};

export default TrainingForm;
