export const ContentType = Object.freeze({
  Campaign: 'campaign',
  Information: 'information',
  Newsletter: 'newsletter',
  Process: 'process',
});

export const ProductType = Object.freeze({
  Patrimony: 'patrimony',
  Responsability: 'responsability',
  Professional: 'professional',
});
