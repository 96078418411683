import gql from 'graphql-tag';

export const getDashboard = gql`
  query getDashboard($today: String!, $today2: timestamp!) {
    brokerTotal: broker_aggregate(where: {status: {_neq: "rejected"}}) {
      aggregate {
        count
      }
    }
    brokerActive: broker_aggregate(where: {status: {_eq: "active"}}) {
      aggregate {
        count
      }
    }
    brokerInactive: broker_aggregate(where: {status: {_eq: "inactive"}}) {
      aggregate {
        count
      }
    }
    brokerSubmitted: broker_aggregate(where: {status: {_eq: "submitted"}}) {
      aggregate {
        count
      }
    }
    brokerPendent: broker_aggregate(where: {status: {_eq: "pendent"}}) {
      aggregate {
        count
      }
    }
    userTotal: user_aggregate(where: {role: {_in: ["user", "team_admin"]}}) {
      aggregate {
        count
      }
    }
    adminActive: user_aggregate(
      where: {role: {_in: ["team_admin"]}, status: {_eq: "active"}}
    ) {
      aggregate {
        count
      }
    }
    adminInactive: user_aggregate(
      where: {role: {_in: ["team_admin"]}, status: {_eq: "inactive"}}
    ) {
      aggregate {
        count
      }
    }
    userActive: user_aggregate(
      where: {role: {_in: ["user"]}, status: {_eq: "active"}}
    ) {
      aggregate {
        count
      }
    }
    userInactive: user_aggregate(
      where: {role: {_in: ["user"]}, status: {_eq: "inactive"}}
    ) {
      aggregate {
        count
      }
    }
    userInvites: invite_aggregate(
      where: {role: {_eq: "user"}, status: {_eq: "active"}}
    ) {
      aggregate {
        count
      }
    }
    adminInvites: invite_aggregate(
      where: {role: {_eq: "team_admin"}, status: {_eq: "active"}}
    ) {
      aggregate {
        count
      }
    }
    activeTraining: training_aggregate(
      where: {
        status: {_eq: "published"}
        _not: {link: {_eq: ""}}
        date: {_gte: $today}
      }
    ) {
      aggregate {
        count
      }
    }
    soonTraining: training_aggregate(
      where: {status: {_eq: "published"}, link: {_eq: ""}, date: {_gte: $today}}
    ) {
      aggregate {
        count
      }
    }
    pastTraining: training_aggregate(
      where: {
        status: {_eq: "published"}
        _not: {link: {_eq: ""}}
        date: {_lt: $today}
      }
    ) {
      aggregate {
        count
      }
    }
    scheduleCampaign: content(
      where: {
        type: {_eq: "campaign"}
        publish_at: {_gte: $today2}
        status: {_eq: "published"}
      }
    ) {
      id
      title
      publish_at
    }
    scheduleInfo: content(
      where: {
        type: {_eq: "information"}
        publish_at: {_gte: $today2}
        status: {_eq: "published"}
      }
    ) {
      id
      title
      publish_at
    }
    scheduleNewsletter: content(
      where: {
        type: {_eq: "newsletter"}
        publish_at: {_gte: $today2}
        status: {_eq: "published"}
      }
    ) {
      id
      title
      publish_at
    }
  }
`;

export const getContentList = gql`
  query getContentList(
    $offset: Int
    $limit: Int
    $where: content_bool_exp
    $order: [content_order_by!]
  ) {
    content(offset: $offset, limit: $limit, where: $where, order_by: $order) {
      id
      title
      publish_at
      type
      status
    }
    content_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

export const getTrainingsList = gql`
  query getTrainingsList(
    $offset: Int
    $limit: Int
    $order: [training_order_by!]
  ) {
    training(offset: $offset, limit: $limit, order_by: $order) {
      id
      name
      date
      teacher
      status
    }
    training_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getCategoriesList = gql`
  query getCategoriesList {
    category {
      id
      name
    }
  }
`;

export const getTraining = gql`
  query getTraining($id: Int!) {
    training(where: {id: {_eq: $id}}) {
      city
      date
      hour
      id
      link
      name
      status
      teacher
      tutorial
      conference_id
      conference_video
      duration
      training_categories(where: {training_id: {_eq: $id}}) {
        id
        category_id
      }
      presences(order_by: {user: {forename: asc}}) {
        id
        user {
          forename
          email
        }
        status
        certificate_url
      }
      attachments(order_by: {created_at: desc}) {
        id
        file_name
        field_name
        file_size
        url
      }
    }
    category {
      id
      name
    }
  }
`;

export const getCategories = gql`
  query getCategories {
    category {
      id
      name
    }
  }
`;

export const getContent = gql`
  query getContentSingle($id: Int!) {
    content_by_pk(id: $id) {
      id
      title
      body
      type
      publish_at
      status
      slug
      attachments {
        id
        url
        field_name
        file_size
      }
    }
  }
`;

export const getBrokersList = gql`
  query getBroker(
    $offset: Int
    $limit: Int
    $order: [broker_order_by!]
    $where: broker_bool_exp!
  ) {
    broker_aggregate(order_by: $order, where: $where) {
      aggregate {
        count
      }
    }
    broker(offset: $offset, limit: $limit, order_by: $order, where: $where) {
      id
      name
      vat
      status
      created_at
    }
  }
`;

export const getInviteList = gql`
  query getInviteList($id: Int) {
    invite(broker_id: {_eq: $id}) {
      id
      name
      email
      created_at
      status
      role
    }
  }
`;

export const getBroker = gql`
  query getBroker($id: Int) {
    broker(where: {id: {_eq: $id}}) {
      id
      vat
      phone
      created_at
      name
      status
      allow_submission
      users(order_by: {forename: asc, email: asc}) {
        email
        forename
        id
        status
        role
      }
      f1: attachments(
        where: {field_name: {_eq: "f1"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f2: attachments(
        where: {field_name: {_eq: "f2"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f3: attachments(
        where: {field_name: {_eq: "f3"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f4: attachments(
        where: {field_name: {_eq: "f4"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
      f5: attachments(
        where: {field_name: {_eq: "f5"}}
        order_by: {updated_at: desc}
        limit: 1
      ) {
        id
        field_name
        file_size
        url
      }
    }
    invite(
      where: {broker_id: {_eq: $id}, _not: {user: {id: {_gt: 0}}}},
      order_by: {name: asc, email: asc}
    ) {
      id
      name
      email
      created_at
      status
      role
    }
  }
`;

export const getProfile = gql`
  query getProfile($sub: String) {
    user(where: {sub: {_eq: $sub}}) {
      id
      forename
      surname
      email
    }
  }
`;

export const getProducts = gql`
  query getProducts($offset: Int, $limit: Int, $order: [product_order_by!]) {
    product(offset: $offset, limit: $limit, order_by: $order) {
      id
      title
      short_title
      updated_at
      type
      status
      integration_code
      import_status
    }
    product_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getSortedProducts = gql`
  query getSortedProducts {
    patrimony: product(
      order_by: {order: asc}
      where: {type: {_eq: "patrimony"}, status: {_eq: "published"}}
    ) {
      id
      title
      short_title
      type
      order
    }
    responsability: product(
      order_by: {order: asc}
      where: {type: {_eq: "responsability"}, status: {_eq: "published"}}
    ) {
      id
      title
      short_title
      type
      order
    }
    professional: product(
      order_by: {order: asc}
      where: {type: {_eq: "professional"}, status: {_eq: "published"}}
    ) {
      id
      title
      short_title
      type
      order
    }
  }
`;

export const getGroups = gql`
  query getGroups {
    group {
      id
      title
      products {
        id
        title
      }
    }
    product(where: {group_id: {_is_null: true}}) {
      id
      title
    }
  }
`;

export const getProduct = gql`
  query getProduct($id: Int!) {
    product_by_pk(id: $id) {
      id
      title
      short_title
      body
      type
      integration_code
      slug
      status
      helper
      conditions
      has_simulator
      import_status
    }
  }
`;

export const setting = gql`
  query setting {
    setting {
      name
      value
      secured
    }
  }
`;

export const getUserPasswordToken = gql`
  query user($email: String!) {
    user(where: {email: {_eq: $email}}) {
      reset_password_token
    }
  }
`;
