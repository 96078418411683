import React from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  ModelWithOverlay,
  Flex,
  Text,
  Button,
  Heading,
  FormTextarea,
  locale,
  theme,
} from '@innovago/ui';

const validationSchema = Yup.object().shape({
  rejection: Yup.string().required(locale.required),
});

export const RejectPermanentOverlay = props => {
  const {setRejectPermanentOverlay, rejectBrokerPermanentlyStatus, id} = props;

  const formik = useFormik({
    initialValues: {rejection: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      setRejectPermanentOverlay(false);
      rejectBrokerPermanentlyStatus({
        variables: {
          id: id,
          observations: values.rejection,
        },
      });
    },
  });

  return (
    <ModelWithOverlay
      top="50%"
      onClose={() => setRejectPermanentOverlay(false)}
    >
      <Heading
        level="3"
        color={theme.colors.brand}
        font={theme.fonts.lato}
        size="2rem"
        padding="0 0 2rem"
      >
        Rejeitar definitivamente um broker
      </Heading>
      <Text padding="0 0 1rem">{locale.brokerRefusalIntro}</Text>
      <form onSubmit={formik.handleSubmit}>
        <FormTextarea
          name="rejection"
          margin="0 0 2rem"
          formik={formik}
        ></FormTextarea>
        <Flex justify="flex-end">
          <Button type="submit" width="auto" filled>
            {locale.submit}
          </Button>
        </Flex>
      </form>
    </ModelWithOverlay>
  );
};
