import React, {useContext} from 'react';
import {usePath} from 'hookrouter';

import {Navbar, locale, theme} from '@innovago/ui';

import {ContentType} from '../../config/content';
import {AuthContext} from '../../contexts/AuthContext';

const Header = () => {
  const path = usePath();
  const {isSignedIn, logout} = useContext(AuthContext);

  return (
    <Navbar
      top="106px"
      barColor={theme.colors.darkBlue}
      activeItem={path}
      topBar={
        isSignedIn()
          ? {
              items: [
                {
                  label: locale.profile,
                  url: '/profile',
                },
              ],

              onLogout: logout,
            }
          : null
      }
      bottomBar={
        isSignedIn()
          ? [
              {
                label: 'brokers',
                url: '/brokers',
              },
              {
                label: locale.training.plural,
                url: '/training',
              },
              {
                label: locale.trainingCat.plural,
                url: '/training-categories',
              },
              {
                label: 'Produtos',
                url: '#',
                hasSubMenu: true,
                type: false,
                submenu: {
                  label: 'Dados sobre os produtos.',
                  columns: [
                    {
                      label: 'Produtos',
                      items: [
                        {
                          label: locale.product.plural,
                          slug: 'product',
                        },
                        {
                          label: locale.simulator.plural,
                          slug: 'simulator',
                        },
                        {
                          label: locale.groups.plural,
                          slug: 'groups',
                        },
                        {
                          label: 'Ordem no menu',
                          slug: 'menu-order',
                        },
                      ],
                    },
                  ],
                },
              },
              {
                label: 'Comunicações',
                url: '#',
                hasSubMenu: true,
                type: false,
                submenu: {
                  label: 'Todas a informações.',
                  columns: [
                    {
                      label: 'Comunicações',
                      items: [
                        ...Object.values(ContentType).map(item => {
                          return {
                            label: locale[item].plural,
                            slug: item,
                          };
                        }),
                      ],
                    },
                  ],
                },
              },
              {
                label: locale.settings,
                url: '/settings',
              },
            ]
          : []
      }
    />
  );
};

export default Header;
