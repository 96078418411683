import React, {useState} from 'react';
import {navigate} from 'hookrouter';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import {
  Box,
  Grid2,
  FormInput,
  FormSelect,
  Search,
  Button,
  Heading,
  Loader,
  Pagination,
  StickyGrid,
  Table,
  TableMenu,
  Wrapper,
  Badge,
  NotificationContainer,
  useNotification,
  locale,
  theme,
  formatDate,
} from '@innovago/ui';

import {getContentList} from '../../graphql/queries';
import {deleteContent as deleteContentMutation} from '../../graphql/mutations';
import {ContentType} from '../../config/content';

const validationSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
});

const ContentList = ({type}) => {
  const [notifications, notify] = useNotification();
  const limit = 10;
  const [status, setStatus] = useState(['draft', 'published']);
  const [search, setSearch] = useState(null);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({updated_at: 'desc'});
  const today = moment().format('YYYY-MM-DD');

  let where = {
    type: {_eq: type},
    status: {_in: status},
  };

  if (search) {
    where.title = {_ilike: search};
  }

  const queryVars = {
    limit,
    order,
    offset,
    where,
  };
  const {data, loading} = useQuery(getContentList, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: () => notify(),
  });
  const [deleteContent] = useMutation(deleteContentMutation, {
    onCompleted: () => notify(locale.deletedSuccessfully, 'success'),
    onError: () => notify(),
  });

  function handleRemoveItem(item) {
    deleteContent({
      refetchQueries: ['getContentList'],
      variables: {
        id: item.id,
      },
    });
  }

  const formik = useFormik({
    initialValues: {
      search: '',
      status: '0',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      values.status === '0'
        ? setStatus(['draft', 'published'])
        : setStatus(values.status);
      values.search.trim().length === 0
        ? setSearch(null)
        : setSearch('%' + values.search + '%');
    },
  });

  if (loading) {
    return <Loader />;
  }

  if (!Object.values(ContentType).includes(type)) {
    navigate('/404');
    return null;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />

      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          {locale[type].plural}
        </Heading>
        <Button href={`/${type}/new`} filled>
          {locale.add}
        </Button>
      </StickyGrid>

      <Box
        backgroundColor="rgba(134,177,184,.1)"
        margin="0 0 1rem"
        padding="1.125rem 1rem"
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid2>
            <Box position="relative">
              <FormInput
                type="text"
                name="search"
                label={locale.search}
                placeholder="Nome/NIF"
                border={true}
                formik={formik}
              />
              <Box position="absolute" bottom="28px" right="10px">
                <Button type="submit" color={theme.colors.darkBlue}>
                  <Search />
                </Button>
              </Box>
            </Box>
            <Box>
              <FormSelect
                formik={formik}
                label={locale.status}
                name="status"
                options={[
                  {label: 'Todos', value: '0'},
                  {label: 'Rascunho', value: 'draft'},
                  {label: 'Publicado', value: 'published'},
                ]}
                allowEmpty={false}
                onChange={e => {
                  formik.setFieldValue('status', e.target.value);
                  formik.handleSubmit();
                }}
              />
            </Box>
          </Grid2>
        </form>
      </Box>
      <Table
        spacer
        loading={loading}
        columns={[
          {
            label: locale.title,
            property: 'title',
            sortable: true,
            format: item => item.title,
          },
          {
            label: locale.publishAt,
            property: 'publish_at',
            sortable: true,
            format: item => formatDate(item.publish_at),
          },
          {
            label: locale.status,
            property: 'status',
            format: item =>
              item.status === 'published' && item.publish_at > today ? (
                <Badge label={locale.published} type="scheduled" />
              ) : (
                <Badge label={locale[item.status]} type={item.status} />
              ),
          },
          {
            label: locale.actions,
            width: '100px',
            format: item => (
              <TableMenu
                actions={[
                  {
                    label: 'editar',
                    onClick: () => navigate(`/${type}/${item.id}/edit`),
                  },
                  {
                    label: 'eliminar',
                    onClick: () => handleRemoveItem(item),
                  },
                ]}
              />
            ),
          },
        ]}
        data={data && data.content}
        sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
        onClickSort={sort =>
          setOrder({
            [sort.column]: sort.order,
          })
        }
      />
      {data && data.content_aggregate.aggregate && (
        <Pagination
          current={offset / limit + 1}
          total={data.content_aggregate.aggregate.count}
          onChange={page => setOffset(limit * (page - 1))}
        />
      )}
    </Wrapper>
  );
};

export default ContentList;
