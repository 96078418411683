const {
  REACT_APP_AWS_REGION,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_CLIENT_ID,
  REACT_APP_COGNITO_IDENTITY_POOL_ID
} = process.env;

export default {
  Auth: {
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
    identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH'
  }
};
