import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Heading,
  Box,
  FormInput,
  FormSelect,
  Grid2,
  Wrapper,
  locale,
  theme,
} from '@innovago/ui';

import Editor from '../Editor';
import FormHeader from '../../components/FormHeader';
import SlugField from '../SlugField';
import {ProductType} from '../../config/content';

const validationSchema = Yup.object().shape({
  title: Yup.string(),
  type: Yup.string().required(locale.required),
  integration_code: Yup.string(),
});

const getInitialData = content => {
  let data = {
    blocks: [],
  };

  try {
    const parsed = JSON.parse(content);

    if (parsed && parsed.length) {
      data.blocks = parsed;
    }
  } catch {
    console.log('Invalid JSON in story content, replacing with empty body.');
  }

  return data;
};

const SimulatorForm = ({
  title,
  initialValues,
  editMode,
  onSubmit = () => {},
}) => {
  const [saved, setSaved] = useState(initialValues.id !== null);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      onSubmit({...values, id: initialValues.id});
    },
  });

  useEffect(() => {
    if (!initialValues.id && formik.values.body && !saved) {
      formik.handleSubmit();
      setSaved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.body, initialValues, saved]);

  return (
    <Wrapper as="form" onSubmit={formik.handleSubmit}>
      <FormHeader title={title} formik={formik} showBadge={editMode} />
      <FormInput
        type="text"
        name="title"
        label={locale.title}
        formik={formik}
      />
      {editMode && (
        <SlugField
          formik={formik}
          name="slug"
          type="product"
          fallbackVal={formik.values.title}
        />
      )}
      <Grid2>
        <FormInput
          type="text"
          name="integration_code"
          label={locale.integrationCode}
          formik={formik}
          disabled={true}
        />
        <FormSelect
          name="type"
          label={locale.productType}
          formik={formik}
          options={Object.values(ProductType).map(value => ({
            value,
            label: locale[value],
          }))}
        />
      </Grid2>
      <Heading level="3" size="1rem" padding="2rem 0 0">
        Texto de ajuda no preenchimento do simulador
      </Heading>
      <Editor
        uploadDetails={{
          callBackAction: 'insertProductAttachments',
          callBackId: initialValues.id,
        }}
        holderCtn="editor1"
        tools={['header', 'fileList', 'list']}
        data={getInitialData(initialValues.helper)}
        onChange={({blocks}) => {
          formik.setFieldValue('helper', JSON.stringify(blocks));
        }}
      />
      <Box
        width="100%"
        height="2px"
        margin="1rem 0"
        backgroundColor={theme.colors.grey}
      ></Box>
      <Heading level="3" size="1rem" padding="2rem 0 0">
        Texto das condições específicas no simulador
      </Heading>
      <Editor
        uploadDetails={{
          callBackAction: 'insertProductAttachments',
          callBackId: initialValues.id,
        }}
        holderCtn="editor2"
        tools={['header', 'fileList', 'list']}
        data={getInitialData(initialValues.conditions)}
        onChange={({blocks}) => {
          formik.setFieldValue('conditions', JSON.stringify(blocks));
        }}
      />
    </Wrapper>
  );
};

export default SimulatorForm;
