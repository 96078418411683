import React, {useState} from 'react';
import {navigate} from 'hookrouter';
import {useMutation, useQuery} from '@apollo/react-hooks';

import {
  NotificationContainer,
  useNotification,
  ItemStatus,
  Loader,
  locale,
} from '@innovago/ui';

import {getCategories} from '../../graphql/queries';

import {
  createTraining as createTrainingMutation,
  deleteTrainingCategories as deleteTrainingCategoriesMutation,
  insertTrainingCategories as insertTrainingCategoriesMutation,
} from '../../graphql/mutations';

import TrainingForm from '../../components/TrainingForm';

const TrainingNew = ({type}) => {
  const [notifications, notify] = useNotification();
  const [id, setId] = useState(null);
  const [cats, setCats] = useState([]);

  const {data, loading} = useQuery(getCategories);

  const [createTraining] = useMutation(createTrainingMutation, {
    onCompleted: ({insert_training: {returning}}) => {
      setId(returning[0].id);
      deleteAllTrainingCategories(returning[0].id);
    },
    onError: () => {
      notify(locale.errorSaving);
    },
  });

  const [deleteTrainingCategories] = useMutation(
    deleteTrainingCategoriesMutation,
    {
      onCompleted: () => insertAllTrainingCategories(),
      onError: () => {
        notify(locale.errorSaving);
      },
    }
  );

  const [insertTrainingCategories] = useMutation(
    insertTrainingCategoriesMutation,
    {
      onCompleted: () => {
        notify(locale.savedSuccessfully, 'success');
        navigate(`/training/${id}/edit`, true, {created: true});
      },
      onError: () => notify(locale.errorSaving),
    }
  );

  const handleCreate = ({id, ...values}) => {
    delete values.training_categories;
    createTraining({
      variables: {objects: {...values}},
    });
  };

  const deleteAllTrainingCategories = id => {
    deleteTrainingCategories({
      variables: {
        id: id,
      },
    });
  };

  const insertAllTrainingCategories = () => {
    insertTrainingCategories({
      variables: {
        objects: cats.map(cat => {
          return {
            category_id: cat,
            training_id: id,
          };
        }),
      },
    });
  };

  if (!data || loading) {
    return <Loader />;
  }

  return (
    <>
      <NotificationContainer {...notifications} />
      <TrainingForm
        title={locale.create + ' ' + locale.training.singular}
        initialValues={{
          id: null,
          name: '',
          city: '',
          date: null,
          hour: '',
          teacher: '',
          link: '',
          tutorial: false,
          conference_id: '',
          conference_video: '',
          duration: 60,
          status: ItemStatus.Draft,
        }}
        categories={data.category}
        cats={cats}
        setCats={setCats}
        onSubmit={values => handleCreate(values)}
      />
    </>
  );
};

export default TrainingNew;
