import './index.css';

export default class Accordion {
  constructor({config, data, api}) {
    this.api = api;

    this._CSS = {
      block: this.api.styles.block,
      loader: this.api.styles.loader,
      wrapper: 'ce-accordion',
      title: 'ce-title',
      item: 'ce-item',
      button: 'ce-button',
      input: 'ce-input',
      label: 'ce-name',
      text: 'ce-text',
      actions: 'ce-actions',
      up: 'ce-up',
      down: 'ce-down',
      delete: 'ce-delete'
    };

    this._placeholderTitle = config.placeholderTitle
      ? config.placeholderTitle
      : 'Title';
    this._placeholderLabel = config.placeholderLabel
      ? config.placeholderLabel
      : 'Item label';
    this._placeholderText = config.placeholderText
      ? config.placeholderText
      : 'Item content';
    this._addLabel = config.addLabel ? config.addLabel : 'Add item';

    this.data = {
      title: data.title || '',
      items: data.items || []
    };

    this.nodes = {
      title: null,
      items: [],
      button: null
    };
  }

  static get toolbox() {
    return {
      icon:
        '<svg width="12" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M4.109 2.08H2.942a.862.862 0 0 0-.862.862v8.116c0 .476.386.862.862.862h5.529a.862.862 0 0 0 .862-.862V7.695H4.11V2.08zm1.905.497v3.29h3.312l-3.312-3.29zM2.942 0h2.74c.326.02.566.076.719.165.153.09.484.413.992.973l3.21 3.346c.347.413.557.683.631.811.111.193.179.446.179.579v5.184A2.942 2.942 0 0 1 8.471 14H2.942A2.942 2.942 0 0 1 0 11.058V2.942A2.942 2.942 0 0 1 2.942 0z" fill-rule="nonzero"/></svg>',
      title: 'Accordion'
    };
  }

  render() {
    const wrapper = document.createElement('div');
    const title = this._createEl(
      [this._CSS.block, this._CSS.title],
      this._placeholderTitle,
      this.data.title,
      'h3'
    );
    const button = document.createElement('button');

    wrapper.classList.add(this._CSS.wrapper);

    button.classList.add(this._CSS.button);
    button.setAttribute('type', 'button');
    button.textContent = this._addLabel;

    wrapper.appendChild(title);
    wrapper.appendChild(button);

    this.nodes.wrapper = wrapper;
    this.nodes.title = title;
    this.nodes.button = button;

    this.data.items.forEach(item => this._addItem(item));

    button.addEventListener('click', () => this._addItem());

    return wrapper;
  }

  save() {
    this.data = {
      title: this.nodes.title.innerHTML,
      items: this.nodes.items.map(item => ({
        label: item.querySelector(`.${this._CSS.label}`).innerHTML,
        text: item.querySelector(`.${this._CSS.text}`).innerHTML
      }))
    };

    return this.data;
  }

  _addLoader(el) {
    el.classList.add(this._CSS.loader);
  }

  _addItem(data) {
    const wrapper = document.createElement('div');

    wrapper.classList.add(this._CSS.item);

    wrapper.appendChild(
      this._createEl(
        [this._CSS.input, this._CSS.label],
        this._placeholderLabel,
        data && data.label
      )
    );

    wrapper.appendChild(
      this._createEl(
        [this._CSS.input, this._CSS.text],
        this._placeholderText,
        data && data.text
      )
    );

    this.nodes.wrapper.insertBefore(wrapper, this.nodes.button);
    this.nodes.items.push(wrapper);

    this._setActions(wrapper);
  }

  _arrayMove(arr, oldI, newI) {
    const element = arr[oldI];

    arr.splice(oldI, 1);
    arr.splice(newI, 0, element);

    return arr;
  }

  _createEl(cssClasses, placeholder, content, tagName = 'div') {
    const el = document.createElement(tagName);

    el.classList.add(...cssClasses);
    el.dataset.placeholder = placeholder;
    el.innerHTML = content || '';
    el.contentEditable = true;
    el.dataset.gramm = false;

    return el;
  }

  _setActions(el) {
    const actions = document.createElement('div');
    const deleteButton = document.createElement('button');
    const upButton = document.createElement('button');
    const downButton = document.createElement('button');

    deleteButton.classList.add(this._CSS.delete);
    deleteButton.setAttribute('type', 'button');
    upButton.classList.add(this._CSS.up);
    upButton.setAttribute('type', 'button');
    downButton.classList.add(this._CSS.down);
    downButton.setAttribute('type', 'button');

    actions.appendChild(upButton);
    actions.appendChild(downButton);
    actions.appendChild(deleteButton);

    actions.classList.add(this._CSS.actions);
    el.appendChild(actions);

    upButton.addEventListener('click', () => {
      const index = this.nodes.items.indexOf(el);
      const prev = this.nodes.items[index - 1];

      if (prev) {
        this.nodes.wrapper.insertBefore(el, prev);
      }

      this.nodes.items = this._arrayMove(this.nodes.items, index, index - 1);
    });

    downButton.addEventListener('click', () => {
      const index = this.nodes.items.indexOf(el);
      const next = this.nodes.items[index + 2];

      if (next) {
        this.nodes.wrapper.insertBefore(el, next);
      } else {
        this.nodes.wrapper.insertBefore(el, this.nodes.button);
      }

      this.nodes.items = this._arrayMove(this.nodes.items, index, index + 1);
    });

    deleteButton.addEventListener('click', e => {
      e.stopPropagation();

      el.remove();
      this.nodes.items = this.nodes.items.filter(item => item !== el);
      this.nodes.button.focus();
    });
  }
}
