import React from 'react';
import {useQuery} from '@apollo/react-hooks';
import moment from 'moment';

//import {AuthContext} from '../../contexts/AuthContext';

import {
  Box,
  Grid3,
  Wrapper,
  Heading,
  Text,
  DataTable,
  NotificationContainer,
  formatDate,
  useNotification,
  Loader
} from '@innovago/ui';

import {getDashboard} from '../../graphql/queries';

export const Dashboard = () => {
  const [notifications, notify] = useNotification();

  const today = moment().format('YYYY-MM-DD');

  const {data, loading} = useQuery(getDashboard, {
    fetchPolicy: 'cache-and-network',
    variables: {
      today: today,
      today2: String(today)
    },
    onError: () => notify()
  });

  if (loading) {
    return <Loader />;
  }

  const brokerData = [
    {label: 'Total', value: data.brokerTotal.aggregate.count},
    {label: 'Activos', value: data.brokerActive.aggregate.count},
    {label: 'Inactivos', value: data.brokerInactive.aggregate.count},
    {label: 'Convidados', value: data.brokerSubmitted.aggregate.count},
    {label: 'Pendentes', value: data.brokerPendent.aggregate.count}
  ];

  const userData = [
    {label: 'Total', value: data.userTotal.aggregate.count},
    {label: 'Admin Activos', value: data.adminActive.aggregate.count},
    {label: 'Admin Inactivos', value: data.adminInactive.aggregate.count},
    {label: 'User Activos', value: data.userActive.aggregate.count},
    {label: 'User Inactivos', value: data.userInactive.aggregate.count},
    {label: 'Convites Admins', value: data.adminInvites.aggregate.count},
    {label: 'Convites Users', value: data.userInvites.aggregate.count}
  ];

  const trainignData = [
    {label: 'Activas', value: data.activeTraining.aggregate.count},
    {label: 'Em breve', value: data.soonTraining.aggregate.count},
    {label: 'Já realizadas', value: data.pastTraining.aggregate.count}
  ];

  const scheduleCampaign = data.scheduleCampaign;
  const scheduleInfo = data.scheduleInfo;
  const scheduleNewsletter = data.scheduleNewsletter;

  return (
    <Box>
      <NotificationContainer {...notifications} />
      <Wrapper>
        <Heading padding="2rem 0" level="1">
          Dashboard
        </Heading>
        <Grid3 align="flex-start" justify="space-between">
          <DataTable title="Brokers" data={brokerData}></DataTable>
          <DataTable title="Utilizadores" data={userData}></DataTable>
          <DataTable title="Formações" data={trainignData}></DataTable>
        </Grid3>
        <Box elevated margin="2rem 0" padding="2rem">
          {scheduleCampaign.length > 0 && (
            <Box padding="0 0 2rem 0">
              <Heading padding="0 0 1rem 0">Campanha Agendadas:</Heading>
              {scheduleCampaign.map(campaign => {
                return (
                  <Text padding="0 0 0 1rem">
                    * {campaign.title} no dia{' '}
                    {formatDate(campaign.published_at)}
                  </Text>
                );
              })}
            </Box>
          )}
          {scheduleInfo.length > 0 && (
            <Box padding="0 0 2rem 0">
              <Heading padding="0 0 1rem 0">Informações Agendadas:</Heading>
              {scheduleInfo.map(campaign => {
                return (
                  <Text padding="0 0 0 1rem">
                    * {campaign.title} no dia{' '}
                    {formatDate(campaign.published_at)}
                  </Text>
                );
              })}
            </Box>
          )}
          {scheduleNewsletter.length > 0 && (
            <Box padding="0 0 2rem 0">
              <Heading padding="0 0 1rem 0">Newsletter Agendadas:</Heading>
              {scheduleNewsletter.map(campaign => {
                return (
                  <Text padding="0 0 0 1rem">
                    * {campaign.title} no dia{' '}
                    {formatDate(campaign.published_at)}
                  </Text>
                );
              })}
            </Box>
          )}
        </Box>
      </Wrapper>
    </Box>
  );
};
