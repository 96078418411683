import React from 'react';
import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {createUploadLink} from 'apollo-upload-client';
import {setContext} from 'apollo-link-context';
import {useRoutes} from 'hookrouter';
import {ApolloProvider} from '@apollo/react-hooks';

import Header from './components/Header';
import {Flex} from '@innovago/ui';
import routes from './routes';

import {AuthProvider} from './contexts/AuthContext';

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('token');

  if (token){
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}` || '',
      },
    };
  } else {
    return {
      headers: {
        ...headers,
      },
    };
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    createUploadLink({
      uri: process.env.REACT_APP_API_URL,
    })
  ),
});

const App = () => {
  const routeResult = useRoutes(routes);
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Flex direction="column" justify="start">
          <Header />
          <div style={{flex: 1, width: '100%', height: '100%', minHeight: '100vh' }}>
            {routeResult}
          </div>
        </Flex>
      </AuthProvider>
    </ApolloProvider>
  );
};

export default App;
