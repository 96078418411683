import {formatDate} from '@innovago/ui';

export function formatDataOnReceive(data) {
  for (var key in data) {
    if (data[key] === null) {
      data[key] = '';
    }

    if (key === 'publish_at') {
      data[key] = formatDate(data[key]);
    }
  }

  return data;
}

export function formatDataOnSend(data) {
  delete data.attachments;

  for (var key in data) {
    if (
      typeof data[key] === 'string' &&
      data[key].trim() === '' &&
      key !== 'title'
    ) {
      data[key] = null;
    }
  }

  return data;
}
