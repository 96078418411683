import React, {useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Grid2,
  Button,
  Heading,
  Loader,
  Pagination,
  StickyGrid,
  Table,
  Wrapper,
  FormInput,
  FormSelect,
  Search,
  locale,
  theme,
  formatDate,
  NotificationContainer,
  useNotification,
  Badge,
} from '@innovago/ui';

import {getBrokersList} from '../../graphql/queries';

const validationSchema = Yup.object().shape({
  search: Yup.string(),
  status: Yup.string(),
});

const BrokerList = () => {
  const [notifications, notify] = useNotification();

  const limit = 10;
  const [status, setStatus] = useState([
    'active',
    'inactive',
    'submitted',
    'pendent',
  ]);
  const [search, setSearch] = useState(null);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({created_at: 'desc'});

  let where = {};

  if (search) {
    where._and = [
      {status: {_in: status}},
      {_or: [{name: {_ilike: search}}, {vat: {_ilike: search}}]},
    ];
  } else {
    where = {status: {_in: status}};
  }

  const queryVars = {
    limit,
    order,
    offset,
    where,
  };

  const {data, loading} = useQuery(getBrokersList, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: () => {
      notify();
    },
  });

  const formik = useFormik({
    initialValues: {
      search: '',
      status: '0',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      values.status === '0'
        ? setStatus(['active', 'inactive', 'submitted', 'pendent', 'rejected'])
        : setStatus(values.status);
      values.search.trim().length === 0
        ? setSearch(null)
        : setSearch('%' + values.search + '%');
    },
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          Brokers
        </Heading>
      </StickyGrid>
      <Box
        backgroundColor="rgba(134,177,184,.1)"
        margin="0 0 1rem"
        padding="1.125rem 1rem"
      >
        <form onSubmit={formik.handleSubmit}>
          <Grid2>
            <Box position="relative">
              <FormInput
                type="text"
                name="search"
                label={locale.search}
                placeholder="Nome/NIF"
                border={true}
                formik={formik}
              />
              <Box position="absolute" bottom="28px" right="10px">
                <Button type="submit" color={theme.colors.darkBlue}>
                  <Search />
                </Button>
              </Box>
            </Box>
            <Box>
              <FormSelect
                formik={formik}
                label={locale.status}
                name="status"
                options={[
                  {label: 'Todos', value: '0'},
                  {label: 'Activos', value: 'active'},
                  {label: 'Inactivos', value: 'inactive'},
                  {label: 'Enviados', value: 'submitted'},
                  {label: 'Pendentes', value: 'pendent'},
                  {label: 'Rejeitados', value: 'rejected'},
                ]}
                allowEmpty={false}
                onChange={e => {
                  formik.setFieldValue('status', e.target.value);
                  formik.handleSubmit();
                }}
              />
            </Box>
          </Grid2>
        </form>
      </Box>
      <Table
        loading={loading}
        columns={[
          {
            label: locale.name,
            property: 'name',
            sortable: true,
            format: item => item.name,
          },
          {
            label: locale.vat,
            property: 'vat',
            sortable: true,
            format: item => item.vat,
          },
          {
            label: locale.broker.createdAt,
            property: 'created_at',
            sortable: true,
            format: item => formatDate(item.created_at),
          },
          {
            label: locale.status,
            property: 'status',
            sortable: true,
            format: item => (
              <Badge type={item.status} label={locale.statusObj[item.status]} />
            ),
          },
          {
            label: locale.actions,
            format: item => (
              <Button filled href={`/brokers/${item.id}`}>
                {locale.seeDetails}
              </Button>
            ),
          },
        ]}
        data={data && data.broker}
        sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
        onClickSort={sort =>
          setOrder({
            [sort.column]: sort.order,
          })
        }
      />
      <Box margin="0 0 2rem 0"></Box>
      {data && data.broker_aggregate.aggregate && (
        <Pagination
          pageSize={20}
          current={offset / 20 + 1}
          total={data.broker_aggregate.aggregate.count}
          onChange={page => setOffset(20 * (page - 1))}
        />
      )}
      <Box margin="0 0 2rem 0"></Box>
    </Wrapper>
  );
};

export default BrokerList;
