import React, {useState} from 'react';
import {navigate} from 'hookrouter';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {
  Button,
  Heading,
  Loader,
  Pagination,
  StickyGrid,
  Table,
  TableMenu,
  Wrapper,
  Badge,
  NotificationContainer,
  useNotification,
  locale,
  formatDate
} from '@innovago/ui';

import {getTrainingsList} from '../../graphql/queries';
import {deleteTraining as deleteTrainingMutation} from '../../graphql/mutations';

const Trainings = () => {
  const [notifications, notify] = useNotification();
  const limit = 10;
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState({updated_at: 'desc'});
  const queryVars = {
    limit,
    order,
    offset
  };
  const {data, loading} = useQuery(getTrainingsList, {
    fetchPolicy: 'cache-and-network',
    variables: queryVars,
    onError: () => notify()
  });
  const [deleteTraining] = useMutation(deleteTrainingMutation, {
    onCompleted: () => notify(locale.deletedSuccessfully, 'success'),
    onError: () => notify()
  });

  function handleRemoveItem(item) {
    deleteTraining({
      refetchQueries: ['getTrainingsList'],
      variables: {
        id: item.id
      }
    });
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          {locale.training.plural}
        </Heading>
        <Button href="/training/new" filled>
          {locale.add}
        </Button>
      </StickyGrid>
      <Table
        loading={loading}
        columns={[
          {
            label: locale.title,
            property: 'name',
            sortable: true,
            format: item => item.name
          },
          {
            label: locale.teacher,
            property: 'teacher',
            sortable: true,
            format: item => item.teacher
          },
          {
            label: locale.date,
            property: 'date',
            sortable: true,
            format: item => formatDate(item.date)
          },
          {
            label: locale.status,
            property: 'status',
            sortable: true,
            format: item => (
              <Badge label={locale[item.status]} type={item.status} />
            )
          },
          {
            label: locale.actions,
            width: '100px',
            format: item => (
              <TableMenu
                actions={[
                  {
                    label: 'editar',
                    onClick: () => navigate(`/training/${item.id}/edit`)
                  },
                  {
                    label: 'eliminar',
                    onClick: () => handleRemoveItem(item)
                  }
                ]}
              />
            )
          }
        ]}
        data={data && data.training}
        sort={{column: Object.keys(order)[0], order: Object.values(order)[0]}}
        onClickSort={sort =>
          setOrder({
            [sort.column]: sort.order
          })
        }
      />
      {data && data.training_aggregate.aggregate && (
        <Pagination
          current={offset / limit + 1}
          total={data.training_aggregate.aggregate.count}
          onChange={page => setOffset(limit * (page - 1))}
        />
      )}
    </Wrapper>
  );
};

export default Trainings;
