import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Box,
  FormDate,
  FormInput,
  FormFile,
  Grid2,
  Wrapper,
  Image,
  Text,
  locale,
  parseDate,
} from '@innovago/ui';

import {ContentType} from '../../config/content';
import Editor from '../../components/Editor';
import FormHeader from '../../components/FormHeader';
import SlugField from '../SlugField';
import {formatDataOnSend} from '../../utils/data';
import {uploadFile as uploadFileFetch} from '../../utils/uploadFile';

const validationSchema = Yup.object().shape({
  title: Yup.string().nullable(),
  publish_at: Yup.date().required(locale.required),
});

const getInitialData = content => {
  let data = {
    blocks: [],
  };

  try {
    const parsed = JSON.parse(content);

    if (parsed && parsed.length) {
      data.blocks = parsed;
    }
  } catch {
    console.log('Invalid JSON in story content, replacing with empty body.');
  }

  return data;
};

function formTools(type) {
  let tools = ['header', 'list', 'fileList', 'delimiter'];

  if (type === ContentType.Process) {
    tools = [...tools, 'accordion', 'formPlaceholder'];
  }

  return tools;
}

function fileToDataUrl(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = () => {
      reject();
    };
    fr.readAsDataURL(file);
  });
}

async function uploadFile(file, contentID) {
  const error = {
    success: 0,
  };

  try {
    const fileData = await fileToDataUrl(file);
    const data = await uploadFileFetch({
      callBackAction: 'insertContentAttachments',
      callBackId: contentID,
      media: {
        fieldName: 'thumbnail',
        fileName: file.name,
        type: file.type,
        file: fileData,
      },
    });

    return {
      success: 1,
      file: {
        id: data.id,
        size: file.size,
        url: data.url,
        name: file.name,
      },
    };
  } catch (err) {
    throw error;
  }
}

const ContentForm = ({
  title,
  editMode,
  initialValues,
  onSubmit = () => {},
  onDeleteThumbnail = () => {},
}) => {
  const [saved, setSaved] = useState(initialValues.id !== null);
  const [thumbnail, setThumbnail] = useState({
    id: null,
    name: 'thumbnail',
    size: 0,
    label: 'Cartaz da campanha',
    file: null,
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      onSubmit({...formatDataOnSend(values), id: initialValues.id});
    },
  });
  const isProcess = formik.values.type === ContentType.Process;
  const isCampaign = formik.values.type === ContentType.Campaign;

  useEffect(() => {
    if (!initialValues.id && formik.values.body && !saved) {
      formik.handleSubmit();
      setSaved(true);
    }
    if (initialValues && initialValues.attachments) {
      const thumb = initialValues.attachments.filter(
        attachment => attachment.field_name === 'thumbnail'
      );
      if (thumb.length > 0) {
        setThumbnail({
          id: thumb[0].id,
          name: 'thumbnail',
          size: thumb[0].file_size,
          label: 'Cartaz da campanha',
          file: thumb[0].url,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.body, initialValues, saved]);

  const onThumbRemove = async () => {
    const deleteStatus = await onDeleteThumbnail(thumbnail.id);

    if (deleteStatus.data.delete_attachment.returning.length > 0) {
      setThumbnail({
        id: null,
        name: 'thumbnail',
        size: 0,
        label: 'Cartaz da campanha',
        file: null,
      });
    }
  };

  async function onThumbChange(e) {
    const uploadedFile = e.target.files[0];
    const thumb = await uploadFile(uploadedFile, formik.values.id);

    setThumbnail({
      id: thumb.file.id,
      name: 'thumbnail',
      size: thumb.file.size,
      label: 'Cartaz da campanha',
      file: thumb.file.url,
    });
  }

  return (
    <Wrapper as="form" onSubmit={formik.handleSubmit}>
      <FormHeader
        title={title}
        formik={formik}
        showBadge={editMode}
        schedule={parseDate(formik.values.publish_at) > new Date()}
      />
      {isProcess ? (
        <Box>
          <FormInput
            type="text"
            name="title"
            label={locale.title}
            formik={formik}
          />
          {editMode && (
            <SlugField
              formik={formik}
              name="slug"
              type={formik.values.type}
              fallbackVal={formik.values.title}
            />
          )}
        </Box>
      ) : (
        <Grid2 overflow="initial">
          <FormInput
            type="text"
            name="title"
            label={locale.title}
            formik={formik}
          />
          <FormDate
            formik={formik}
            name="publish_at"
            label={locale.publishAt}
          />
        </Grid2>
      )}
      {isCampaign && (
        <Grid2 align="flex-start">
          <Box>
            <FormFile
              key="thumbnail"
              name="thumbnail"
              data={thumbnail}
              type="file"
              required
              formik={formik}
              onChange={onThumbChange}
              onRemove={onThumbRemove}
            />
            <Text size=".7rem">
              A imagem deve ter as dimensões 210px x 250px.
            </Text>
          </Box>
          {thumbnail && thumbnail.file && <Image src={thumbnail.file} />}
        </Grid2>
      )}
      <Editor
        uploadDetails={{
          callBackAction: 'insertContentAttachments',
          callBackId: initialValues.id,
        }}
        data={getInitialData(initialValues.body)}
        onChange={({blocks}) => {
          formik.setFieldValue('body', JSON.stringify(blocks));
        }}
        tools={formTools(formik.values.type)}
      />
    </Wrapper>
  );
};

export default ContentForm;
