import React, {useContext} from 'react';
import {navigate} from 'hookrouter';

import {AuthContext} from '../../contexts/AuthContext';

const Private = ({component: Component, ...rest}) => {
  const {isSignedIn} = useContext(AuthContext);

  if (!isSignedIn()) {
    navigate('/');
    return null;
  }

  return <Component {...rest} />;
};

export default Private;
