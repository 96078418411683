import React, {useEffect} from 'react';
import {navigate, useQueryParams} from 'hookrouter';
import {useQuery, useMutation} from '@apollo/react-hooks';

import {getProduct} from '../../graphql/queries';

import {
  Loader,
  NotificationContainer,
  useNotification,
  locale,
} from '@innovago/ui';

import SimulatorForm from '../../components/SimulatorForm';
import {updateProduct as updateProductMutation} from '../../graphql/mutations';

import {formatDataOnReceive} from '../../utils/data';

const SimulatorEdit = ({id}) => {
  const [queryParams] = useQueryParams();
  const [notifications, notify] = useNotification();
  const {data, loading, error} = useQuery(getProduct, {
    variables: {
      id,
    },
  });
  const [updateProduct] = useMutation(updateProductMutation, {
    onCompleted: () => notify(locale.savedSuccessfully, 'success'),
    onError: () => notify(locale.errorSaving),
  });

  useEffect(() => {
    if (queryParams.created) {
      notify(locale.savedSuccessfully, 'success');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  function handleUpdate({id, __typename, ...rest}) {
    updateProduct({
      variables: {id, set: rest},
    });
  }

  if (loading) {
    return <Loader />;
  }

  if (!data || (data && !data.product_by_pk) || error) {
    navigate('/404');
    return null;
  }

  return (
    <>
      <NotificationContainer {...notifications} />
      <SimulatorForm
        title={locale.edit + ' ' + locale.product.singular}
        initialValues={formatDataOnReceive(data.product_by_pk)}
        onSubmit={handleUpdate}
        editMode
      />
    </>
  );
};

export default SimulatorEdit;
