import {locale} from '@innovago/ui';

const {REACT_APP_UPLOAD_URL} = process.env;

export async function uploadFile(
  details = {
    callBackAction: '',
    callBackId: '',
    media: {
      fileName: '',
      type: '',
      file: '',
    },
  }
) {
  try {
    const response = await fetch(REACT_APP_UPLOAD_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(details),
    });
    const {data} = await response.json();

    if (data) {
      const {returning} = data.insert_attachment;

      if (returning.length) {
        return returning[0];
      }
    }

    throw new Error(locale.errorUpload);
  } catch {
    throw new Error(locale.errorUpload);
  }
}
