import React, {useState} from 'react';
import {useQuery, useMutation} from '@apollo/react-hooks';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Heading,
  Loader,
  StickyGrid,
  Table,
  TableMenu,
  Wrapper,
  Box,
  FormInput,
  NotificationContainer,
  useNotification,
  locale,
} from '@innovago/ui';

import {getCategoriesList} from '../../graphql/queries';
import {deleteCategory as deleteCategoryMutation} from '../../graphql/mutations';
import {insertCategory as insertCategoryMutation} from '../../graphql/mutations';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(locale.required),
});

const TrainingsCategories = () => {
  const [notifications, notify] = useNotification();
  const [addMode, setAddMode] = useState(false);

  const {data, loading} = useQuery(getCategoriesList, {
    fetchPolicy: 'cache-and-network',
    onError: () => notify(),
  });

  const [insertCategory] = useMutation(insertCategoryMutation, {
    onCompleted: () => {
      notify(locale.categoryInsertionSuccess, 'success');
      setAddMode(false);
    },
    onError: () => notify(locale.categoryInsertionError, 'error'),
  });

  const [deleteCategory] = useMutation(deleteCategoryMutation, {
    onCompleted: () => {
      notify(locale.categoryDeletionSuccess, 'success');
      setAddMode(false);
    },
    onError: () => notify(locale.categoryDeletionError, 'error'),
  });

  const handleRemoveItem = item => {
    deleteCategory({
      refetchQueries: ['getCategoriesList'],
      variables: {
        id: item.id,
      },
    });
  };

  const formik = useFormik({
    initialValues: {name: ''},
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    onSubmit: async values => {
      insertCategory({
        refetchQueries: ['getCategoriesList'],
        variables: {name: values.name},
      });
    },
  });

  const showAddCategory = item => {
    setAddMode(true);
  };

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <Wrapper>
      <NotificationContainer {...notifications} />
      <StickyGrid>
        <Heading padding="2rem 0" level="1">
          {locale.trainingCat.plural}
        </Heading>
        {!addMode && (
          <Button filled onClick={() => showAddCategory()}>
            {locale.add}
          </Button>
        )}
      </StickyGrid>
      {addMode && (
        <Box padding="2rem 0">
          <form onSubmit={formik.handleSubmit}>
            <FormInput type="text" formik={formik} name="name" width="100%" />
            <Button filled type="submit">
              Adicionar
            </Button>
          </form>
        </Box>
      )}
      <Table
        loading={loading}
        columns={[
          {
            label: locale.title,
            property: 'name',
            sortable: false,
            format: item => item.name,
          },
          {
            label: locale.actions,
            width: '100px',
            format: item => (
              <TableMenu
                actions={[
                  {
                    label: 'eliminar',
                    onClick: () => handleRemoveItem(item),
                  },
                ]}
              />
            ),
          },
        ]}
        data={data && data.category}
      />
    </Wrapper>
  );
};

export default TrainingsCategories;
